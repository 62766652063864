import React from 'react';
import Offline from '../Offline';
import ConnectionChange from '../../lib/connection_change';

export default class Blog_Post_1 extends React.Component{

  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }


  componentDidMount(){
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    window.$("body").addClass("masthead-fixed list-view full-width singular");
  }
  
  render(){
    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
      <div id="main-content" className="single-page-content">
        <div id="primary" className="content-area">
          <div id="content" className="page-content site-content" role="main">
            <article id="post-139" className="post-139 post type-post status-publish format-standard has-post-thumbnail hentry category-wordpress tag-design tag-plugin tag-wordpress">
              <header className="entry-header">
                <div className="entry-meta entry-meta-top">
                  <span><a href="#/" rel="category tag">WordPress</a></span>
                </div>{/* .entry-meta */}
                <h2 className="entry-title">How to Make a WordPress Plugin Extensible</h2>
              </header>{/* .entry-header */}
              <div className="post-thumbnail">
                <img loading="lazy" src="/assets/img/blog/blog_post_1_full.jpg" alt="" /> 
              </div>
              <div className="post-content">
                <div className="entry-content">
                  <div className="fw-page-builder-content">
                    <section className="fw-main-row  ">
                      <div className="fw-container">
                        <div className="row">
                          <div className=" col-xs-12 col-sm-12 ">
                            <div id="col_inner_id-5f22428c90a32" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit. Nam cursus lorem a turpis feugiat, et
                                porttitor leo dapibus. In ut tincidunt lectus, id
                                hendrerit enim. Maecenas at nibh eu nulla dignissim
                                posuere. Nullam viverra vitae elit a tempus. Donec
                                quis eleifend eros. Donec imperdiet nisi mi, in
                                ultricies risus porta ac. Nullam laoreet convallis
                                nibh sed congue. Donec nulla ipsum, tincidunt a
                                augue maximus, pellentesque imperdiet lectus. Aenean
                                posuere consequat libero, id efficitur quam dictum
                                volutpat.</p>
                              <p>Donec mollis a lacus a pharetra. Nam facilisis enim
                                erat, in scelerisque eros mollis quis. Aliquam erat
                                volutpat. Nam vel nibh justo. Nunc vestibulum leo a
                                ultricies malesuada. Proin quis volutpat sem. Morbi
                                consequat lacinia pulvinar.</p>
                              <div className="single-image ">
                                <a href="/assets/img/blog/blog_post_1_full.jpg" className="lightbox">
                                  <img loading="lazy" src="/assets/img/blog/blog_post_1_full.jpg" alt="" />
                                </a>
                              </div>
                              <p>Cras commodo, nulla a commodo sodales, nisl mauris
                                interdum lectus, ac mattis lacus purus ut nunc.
                                Fusce volutpat aliquam euismod. Aliquam pulvinar
                                neque turpis, in tincidunt mi varius et. Curabitur
                                vitae tempus mauris, porta dictum ante. Nam
                                pellentesque et mauris a suscipit. Vivamus gravida
                                erat nec elit ullamcorper, quis laoreet metus
                                efficitur. Duis vulputate, mauris a auctor pretium,
                                elit nisl eleifend nulla, non accumsan augue massa
                                quis tellus. Aliquam at justo libero.</p>
                              <p>Donec mollis a lacus a pharetra. Nam facilisis enim
                                erat, in scelerisque eros mollis quis. Aliquam erat
                                volutpat. Nam vel nibh justo. Nunc vestibulum leo a
                                ultricies malesuada. Proin quis volutpat sem. Morbi
                                consequat lacinia pulvinar.</p>
                              <blockquote className="quote fw-quote-left fw-quote-md ">
                                <p>Mauris lectus dolor, varius ut imperdiet nec,
                                  dignissim nec ligula. Cras posuere odio et
                                  finibus accumsan. Mauris in sem non arcu
                                  consectetur posuere sed quis justo. Sed turpis
                                  mauris, aliquet ac lacus nec, tempor condimentum
                                  justo.</p>
                                <footer className="quote-author">
                                  <span>
                                    Edgar D. Wang </span>
                                </footer>
                              </blockquote>
                              <p>Orci varius natoque penatibus et magnis dis
                                parturient montes, nascetur ridiculus mus. Sed
                                vestibulum mauris condimentum ultrices scelerisque.
                                Fusce pulvinar cursus luctus. Cras dapibus placerat
                                magna, quis euismod nisi consequat euismod.
                                Curabitur finibus nisi at justo ultricies, nec
                                congue metus rutrum. Quisque vulputate sollicitudin
                                aliquam. Curabitur posuere auctor dapibus.</p>
                              <p>Donec mollis a lacus a pharetra. Nam facilisis enim
                                erat, in scelerisque eros mollis quis. Aliquam erat
                                volutpat. Nam vel nibh justo. Nunc vestibulum leo a
                                ultricies malesuada. Proin quis volutpat sem. Morbi
                                consequat lacinia pulvinar.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>{/* .entry-content */}
                <div className="entry-meta entry-meta-bottom">
                  <div className="date-author">
                    <span className="entry-date"><a href="#/" rel="bookmark"><i className="fa fa-fw fa-clock-o" /> <time className="entry-date" dateTime="2019-12-04T08:29:37+00:00">December
                          4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                  </div>
                  {/* Share Buttons */}
                  <div className="entry-share btn-group share-buttons">
                    <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/" rel="noreferrer"  className="btn" target="_blank" title="Share on Facebook">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                      <i className="fab fa-linkedin-in" />
                    </a>
                    <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                      <i className="fab fa-digg" />
                    </a>
                  </div>
                  {/* /Share Buttons */}
                </div>
                <div className="post-tags">
                  <span className="tags"><a href="#/" rel="tag">design</a><a href="#/" rel="tag">plugin</a><a href="#/" rel="tag">WordPress</a></span> </div>
              </div>
              <nav className="navigation post-navigation clearfix">
                <div className="nav-links">
                  <a href="#/" rel="prev"><span className="meta-nav"> Best Practices for Animated Progress
                      Indicators</span></a><a href="#/" rel="next"><span className="meta-nav"> An Overview of E-Commerce
                      Platforms</span></a> </div>
                {/* .nav-links */}
              </nav>{/* .navigation */}
            </article>{/* #post-## */}
          </div>{/* #content */}
        </div>{/* #primary */}
      </div>{/* #main-content */}
    </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}