import React from 'react';
import {Link} from 'react-router-dom';

class Footer extends React.Component{
  render(){
    return(
      <footer className="site-footer clearfix">
        <div className="footer-social">
          <ul className="footer-social-links">
            <li>
              <a href="https://github.com/Elanza-48" rel="noreferrer" target="_blank">
                <i className="fab fa-github fa_icons" />
                <span>Github</span></a>
            </li>
            <li>
              <a href="https://t.me/Elanza_48" rel="noreferrer" target="_blank">
                <i className="fab fa-telegram-plane fa_icons" />
                <span>Telegram</span></a>
            </li>
            <li>
              <a href="https://www.instagram.com/__intuitive.hound__" rel="noreferrer" target="_blank">
                <i className="fab fa-instagram fa_icons" />
                <span>Instagram</span></a>
            </li>
          </ul>
        </div>
        <div className="footer-copyrights" >
          <p style={{fontSize: '15px'}}>Created with:&nbsp;&nbsp;
            <a target="_blank" rel="noreffer" href="https://getbootstrap.com/" className="fab fa-bootstrap" />&nbsp;
            <a target="_blank" rel="noreffer" href="https://nodejs.org/en/" className="fab fa-node-js" />&nbsp;
            <a target="_blank" rel="noreffer" href="https://yarnpkg.com/" className="fab fa-yarn" />&nbsp;
            <a target="_blank" rel="noreffer" href="https://reactjs.org/" className="fab fa-react" />&nbsp;
            <a target="_blank" rel="noreffer" href="https://www.docker.com/" className="fab fa-docker" />&nbsp;
            <a target="_blank" rel="noreffer" href="https://aws.amazon.com/" className="fab fa-aws" />
          </p>
        </div>
        <div className="footer-copyrights">
          <p><Link to="/license"><i className="fa fa-university" /> License Agreement. &nbsp;&nbsp;</Link></p>
        </div>
      </footer>
    );
  }
}

export default Footer;
