import React from 'react';
import Offline from '../Offline';
import ConnectionChange from '../../lib/connection_change';

export default class Blog_Post_7 extends React.Component{

  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidMount(){
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    window.$("body").addClass("masthead-fixed list-view full-width singular");
  }
  
  render(){
    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div id="content" className="page-content site-content" role="main">
              <article id="post-96" className="post-96 post type-post status-publish format-standard has-post-thumbnail hentry category-ui tag-animate tag-bar tag-design tag-progress">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">UI</a></span>
                  </div>{/* .entry-meta */}
                  <h2 className="entry-title">Best Practices for Animated Progress Indicators</h2>
                </header>{/* .entry-header */}
                <div className="post-thumbnail">
                  <img loading="lazy" src="/assets/img/blog/blog_post_7_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </div>
                <div className="post-content">
                  <div className="entry-content">
                    <div className="fw-page-builder-content">
                      <section className="fw-main-row  ">
                        <div className="fw-container">
                          <div className="row">
                            <div className=" col-xs-12 col-sm-12 ">
                              <div id="col_inner_id-5f22df5694044" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                                <p>Nulla nulla nisl, sodales ac nulla ac, consequat
                                  vulputate purus. Curabitur tincidunt ipsum vel nibh
                                  rutrum accumsan. Nunc ullamcorper posuere leo, vitae
                                  aliquet risus pharetra in. Integer turpis eros,
                                  iaculis et mi non, pulvinar egestas leo. Etiam
                                  sagittis ex turpis, vitae cursus tortor interdum eu.
                                  Quisque ultrices nunc eget erat vestibulum euismod.
                                  Ut mauris nisi, facilisis at arcu nec, facilisis
                                  porttitor lorem.</p>
                                <p>Vivamus vitae neque molestie, porta libero sed,
                                  tincidunt leo. In nec posuere odio, id rhoncus
                                  lorem. Proin id erat ut dolor condimentum viverra.
                                  Praesent viverra sed dolor ac luctus. Praesent
                                  placerat id lorem quis lacinia.</p>
                                <blockquote className="quote fw-quote-left fw-quote-md ">
                                  <p>Maecenas id finibus felis. Etiam vitae nibh et
                                    felis efficitur pellentesque. Mauris suscipit
                                    sapien nunc, a lacinia nibh feugiat ut. In hac
                                    habitasse platea dictumst.</p>
                                  <footer className="quote-author">
                                    <span>
                                      Larry L. Johnson </span>
                                  </footer>
                                </blockquote>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-xs-12 col-sm-12 ">
                              <div id="col_inner_id-5f22df56948cf" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                                <p>Etiam interdum vulputate risus, vitae elementum neque
                                  consectetur sed. Donec at risus dui. Ut in suscipit
                                  neque. Vestibulum sit amet lobortis magna, commodo
                                  venenatis ante. Cras molestie, ex a auctor lacinia,
                                  risus est aliquam risus, sit amet semper purus
                                  tortor id ante. Donec lacus ipsum, porttitor et
                                  libero a, fringilla auctor quam. Sed in nisl id
                                  libero tincidunt aliquet. Aenean dui ipsum, auctor
                                  ut leo ut, semper dignissim lacus. Suspendisse
                                  faucibus viverra consequat. Maecenas efficitur massa
                                  vel eros sagittis dapibus. Nam lobortis mi in turpis
                                  hendrerit eleifend. Nulla non massa felis.</p>
                                <p>Donec sit amet dolor ante. Vivamus vel massa
                                  accumsan, faucibus quam quis, convallis velit.
                                  Aliquam erat volutpat. Integer imperdiet diam quis
                                  arcu venenatis, quis sagittis nibh rhoncus. Donec
                                  non nisi scelerisque, sodales metus quis, accumsan
                                  mauris. Curabitur volutpat risus rutrum erat
                                  condimentum tristique. Nullam at felis diam. Quisque
                                  dictum felis non ante pretium mollis. Aliquam turpis
                                  neque, varius nec diam a, aliquam pulvinar diam.
                                  Interdum et malesuada fames ac ante ipsum primis in
                                  faucibus. Sed ipsum libero, aliquet sed bibendum
                                  faucibus, semper a dui.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>{/* .entry-content */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T06:36:59+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">animate</a><a href="#/" rel="tag">bar</a><a href="#/" rel="tag">design</a><a href="#/" rel="tag">progress</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <nav className="navigation post-navigation clearfix">
                <div className="nav-links">
                  <a href="#/" rel="prev"><span className="meta-nav">
                      Hello world!</span></a><a href="#/" rel="next"><span className="meta-nav"> Designing the Perfect Feature Comparison
                      Table</span></a> </div>
                {/* .nav-links */}
              </nav>{/* .navigation */}
            </div>{/* #content */}
          </div>{/* #primary */}
        </div>{/* #main-content */}
      </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}