import React from 'react';

export default class PreLoader extends React.Component {

  componentDidMount(){
    var e, a, t;
    window.$(".preloader").fadeOut(800, "linear");
    e = window.$(window).width(); a = ""; 
    t = window.$("#page_container").attr("data-animation");
    (a = window.$(991 < e ? ".page-container" : ".site-main")).addClass("animated " + t);
    window.$(".page-scroll").addClass("add-prespective"); 
    a.addClass("transform3d"); 
    setTimeout(function () {
      window.$(".page-scroll").removeClass("add-prespective");
        a.removeClass("transform3d")
    }, 1e3);
  }

  render(){
    return(
      <div className="preloader">
      <div className="preloader-animation">
        <div className="preloader-spinner">
        </div>
      </div>
    </div>
    );
  }
}