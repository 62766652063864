import React from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Offline from './Offline';
import ConnectionChange from '../lib/connection_change';

export default class Contact extends React.Component{
  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidMount(){
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    window.$("#contact_form").validator().on("submit", function (e) {
      e.preventDefault();
      if (e.isDefaultPrevented()) {
          return window.$.ajax({
              type: "POST",
              url: "/.netlify/functions/contactHandle",
              contentType: 'application/json',
              data: JSON.stringify(window.$(this).serializeArray()),
              success: function (data) {
                  let e= JSON.parse(data);
                  let a = "alert-" + e.type,
                      t = e.message,
                      o = '<div class="alert ' + a + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + t + "</div>";
                      window.$("#contact_form").find(".messages").html(o); window.$("#contact_form")[0].reset();
              },
              error: function (jqXHR,textStatus,errorThrown) {
                  console.error('🍅 feedback-error >> ','{ errorType: ',jqXHR, '| errorThrown: ',errorThrown, '| code: ', textStatus);
                  let a = "alert-danger",
                      t = "Sorry, something went wrong. Try Reloading the page !",
                      o = '<div class="alert ' + a + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + t + "</div>";
                      window.$("#contact_form").find(".messages").html(o); window.$("#contact_form")[0].reset();
              }
          })
      }
    });

    window.$(".form-control").val("");
    window.$(".form-control").on("focusin", function () {
      window.$(this).parent(".form-group").addClass("form-group-focus");
    });
    window.$(".form-control").on("focusout", function () {
        0 === window.$(this).val().length && window.$(this).parent(".form-group").removeClass("form-group-focus");
    });
  }

  render(){
    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div className="page-title">
              <h1>Contact</h1>
              <div className="page-subtitle">
                <h4> Get in Touch</h4>
              </div>
            </div>
            <div id="content" className="page-content site-content single-post" role="main">
              <div className="row">
                <div className=" col-xs-12 col-sm-4 ">
                  <div id="info_list_1" className="info-list-w-icon">
                    <div className="info-block-w-icon">
                      <div className="ci-icon">
                        <i className="linecons linecons-phone" />
                      </div>
                      <div className="ci-text">
                        <h4>(+91) 755-5632-413</h4>
                        <p>You can call and text me for offcial purposes only.</p>
                      </div>
                    </div>
                    <div className="info-block-w-icon">
                      <div className="ci-icon">
                        <i className="linecons linecons-location" />
                      </div>
                      <div className="ci-text">
                        <h4>Kolkata, India</h4>
                        <p>I live and work from Kolkata, West Bengal, India, PIN 700150.</p>
                      </div>
                    </div>
                    <div className="info-block-w-icon">
                      <div className="ci-icon">
                        <i className="linecons linecons-mail" />
                      </div>
                      <div className="ci-text">
                        <h4><a href="mailto:elanza-48@outlook.com">elanza48@outlook.com</a></h4>
                        <p>You can also email me if you wish to collaborate.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-xs-12 col-sm-8 ">
                  <div className="block-title">
                    <h2>How Can I Help You?</h2>
                  </div>
                  <form id="contact_form" className="contact-form" data-toggle="validator">
                    <div className="messages" />
                    <div className="controls two-columns">
                      <div className="fields clearfix">
                        <div className="left-column">
                          <div className="form-group form-group-with-icon">
                            <input id="form_name" type="text" name="name" className="form-control" placeholder="Full Name" required="required" data-error="Name is required." />
                            <div className="form-control-border" />
                            <div className="help-block with-errors" />
                          </div>
                          <div className="form-group form-group-with-icon">
                            <input id="form_email" type="email" name="email" className="form-control" placeholder="Email Address" required="required" data-error="Valid email is required." />
                            <div className="form-control-border" />
                            <div className="help-block with-errors" />
                          </div>
                          <div className="form-group form-group-with-icon">
                            <input id="form_name" type="text" name="subject" className="form-control" placeholder="Subject" data-error="Subject is required." />
                            <div className="form-control-border" />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                        <div className="right-column">
                          <div className="form-group form-group-with-icon">
                            <textarea id="form_message" name="message" className="form-control" placeholder="Message" rows={7} data-error="Please, leave me a message." defaultValue={""} />
                            <div className="form-control-border" />
                            <div className="help-block with-errors" />
                          </div>
                        </div>
                      </div>
                      <div className="h-captcha" data-sitekey="baca4d10-246e-4a70-b31a-121ca73b81e3" data-theme="light" />
                      <HCaptcha sitekey="baca4d10-246e-4a70-b31a-121ca73b81e3" theme="light"/>
                      <input type="submit" className="button btn-send disabled" defaultValue="Send message" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}