import React from 'react';

export default class License extends React.Component{
  render(){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div className="page-title">
              <h1>License</h1>
              <div className="page-subtitle">
                <h4>License Agreement for external usage.</h4>
              </div>
            </div>
            <div id="content" className="page-content site-content single-post" role="main">
              <div className="row">
                <div className=" col-xs-12 col-sm-6">
                  <div className="block-title">
                    <h2>Subject to provisions.</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12">
                  <div className="clearfix">
                    <p>
                    </p><ul>
                      <li><i className="far fa-copyright" />&nbsp;2020, All rights reserved. LMPixels, Rajarshi Kundu.</li>
                      <li>We, together with our licensors, own and control all the copyright and other intellectual
                        property rights in our website and the material on our website; and all the copyright and
                        other intellectual property rights in our website and the material on our website are reserved.</li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
              <div className="p-40" />
              <div className="row">
                <div className=" col-xs-12 col-sm-6">
                  <div className="block-title">
                    <h2>Your legal rights.</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12">
                  <div className="clearfix">
                    <p>
                    </p><ul>
                      <li>View pages from our website in a web browser.</li>
                      <li>Download pages from our website for caching in a web browser.</li>
                      <li>Print pages from our website.</li>
                      <li>Stream audio and video files from our website.</li>
                      <li>Use our website services by means of a web browser], subject to the other provisions of this
                        notice.</li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
              <div className="p-40" />
              <div className="row">
                <div className=" col-xs-12 col-sm-6">
                  <div className="block-title">
                    <h2>Not generally permitted.</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12">
                  <div className="clearfix">
                    <p>
                    </p><h6>Except as expressly permitted by the other provisions of this notice:</h6>
                    <ul>
                      <li>You must not download any material from our website or save any such material to your
                        computer.</li>
                      <li>You may only use our website for your own personal and business purposes.</li>
                      <li>You must not use our website for any other purposes.</li>
                      <li>You must not edit or otherwise modify any material on our website.</li>
                    </ul>
                    <h6>Unless you own or control the relevant rights in the material. You are not allowed to:</h6>
                    <ul>
                      <li>Republish material from our website <i>(including republication on another website)</i>.
                      </li>
                      <li>Sell, rent or sub-license material from our website.</li>
                      <li>Show any material from our website in public.</li>
                      <li>Exploit material from our website for a commercial purpose.</li>
                      <li>Redistribute material from our website, save to the extent expressly permitted by this
                        notice.</li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
              <div className="p-40" />
              <div className="row">
                <div className=" col-xs-12 col-sm-6">
                  <div className="block-title">
                    <h2>Not Acceptable usage.</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12">
                  <div className="clearfix">
                    <p>
                    </p><ul>
                      <li>Use our website in any way or take any action that causes, or may cause, damage to the
                        website or
                        impairment of the performance, availability or accessibility of the website.
                      </li>
                      <li>Use our website in any way that is unlawful, illegal, fraudulent or harmful, or in
                        connection
                        with any unlawful, illegal, fraudulent or harmful purpose or activity.
                      </li>
                      <li>Use our website to copy, store, host, transmit, send, use, publish or distribute any
                        material which
                        consists of <i>(or is linked to)</i> any spyware, computer virus, Trojan horse, worm,
                        keystroke logger,
                        rootkit or other malicious computer software.
                      </li>
                      <li>
                        Conduct any systematic or automated data collection activities <i>(including without
                          limitation scraping,
                          data mining, data extraction and data harvesting)</i> on or in relation to our website
                        without our express
                        written consent.
                      </li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
              <div className="p-40" />
              <div className="row">
                <div className=" col-xs-12 col-sm-6">
                  <div className="block-title">
                    <h2>Report abuse.</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12">
                  <div className="clearfix">
                    <p>
                    </p><ul>
                      <li>If you learn of any unlawful material or activity on our website, or any material or
                        activity that
                        breaches this notice, please let us know.
                      </li>
                      <li>
                        You can let us know about any such material or activity <i>by email or using our abuse
                          reporting form</i>.
                      </li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
              <div className="p-40" />
              <div className="row">
                <div className=" col-xs-12 col-sm-6">
                  <div className="block-title">
                    <h2>Enforcement of Copyright.</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12">
                  <div className="clearfix">
                    <p>
                    </p><ul>
                      <li>We take the protection of our copyright very seriously.</li>
                      <li>If we discover that you have used our copyright materials in contravention of the licence
                        set out in this
                        notice, we may bring legal proceedings against you, seeking monetary damages and/or an
                        injunction to stop
                        you using those materials. You could also be ordered to pay legal costs.
                      </li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
              <div className="p-40" />
              <div className="row">
                <div className=" col-xs-12 col-sm-6">
                  <div className="block-title">
                    <h2>Permissions request.</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12">
                  <div className="clearfix">
                    <p>
                    </p><ul>
                      <li>
                        You may request permission to use the copyright materials on our website by <i>writing to us by
                          email or post,using the contact details published on the website</i>.
                      </li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
              <div className="p-40" />
              <div className="row">
                <div className=" col-xs-12 col-sm-6">
                  <div className="block-title">
                    <h2>Contact us.</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12">
                  <div className="clearfix">
                    <p>
                    </p><ul>
                      <li>
                        Official email ID: <a href="mailto:elanza-48@outlook.com">elanza48@outlook.com</a>
                      </li>
                      <li>
                        Personal email ID: <a href="mailto:rishikundu6@gmail.com">rishikundu6@gmail.com</a>
                      </li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
              <div className="p-40" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}