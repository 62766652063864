import React from 'react';
import Offline from '../Offline';
import ConnectionChange from '../../lib/connection_change';

export default class Blog_Post_6 extends React.Component{

  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidMount(){
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    window.$("body").addClass("masthead-fixed list-view full-width singular");
  }
  
  render(){
    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div id="content" className="page-content site-content" role="main">
              <article id="post-133" className="post-133 post type-post status-publish format-standard has-post-thumbnail hentry category-ui tag-design tag-menu tag-ui tag-video tag-vimeo tag-youtube">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">UI</a></span>
                  </div>{/* .entry-meta */}
                  <h2 className="entry-title">Creative and Innovative Navigation Designs</h2>
                </header>{/* .entry-header */}
                <div className="post-thumbnail">
                  <img loading="lazy" src="/assets/img/blog/blog_post_6_full.jpg" className="attachment-full size-full wp-post-image" alt="" />
                </div>
                <div className="post-content">
                  <div className="entry-content">
                    <div className="fw-page-builder-content">
                      <section className="fw-main-row  ">
                        <div className="fw-container">
                          <div className="row">
                            <div className=" col-xs-12 col-sm-12 ">
                              <div id="col_inner_id-5f2243537b66c" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                                <p>Etiam interdum vulputate risus, vitae elementum neque
                                  consectetur sed. Donec at risus dui. Ut in suscipit
                                  neque. Vestibulum sit amet lobortis magna, commodo
                                  venenatis ante. Cras molestie, ex a auctor lacinia,
                                  risus est aliquam risus, sit amet semper purus
                                  tortor id ante. Donec lacus ipsum, porttitor et
                                  libero a, fringilla auctor quam. Sed in nisl id
                                  libero tincidunt aliquet. Aenean dui ipsum, auctor
                                  ut leo ut, semper dignissim lacus. Suspendisse
                                  faucibus viverra consequat. Maecenas efficitur massa
                                  vel eros sagittis dapibus. Nam lobortis mi in turpis
                                  hendrerit eleifend. Nulla non massa felis.</p>
                                <p>Donec sit amet dolor ante. Vivamus vel massa
                                  accumsan, faucibus quam quis, convallis velit.
                                  Aliquam erat volutpat. Integer imperdiet diam quis
                                  arcu venenatis, quis sagittis nibh rhoncus. Donec
                                  non nisi scelerisque, sodales metus quis, accumsan
                                  mauris. Curabitur volutpat risus rutrum erat
                                  condimentum tristique. Nullam at felis diam. Quisque
                                  dictum felis non ante pretium mollis. Aliquam turpis
                                  neque, varius nec diam a, aliquam pulvinar diam.
                                  Interdum et malesuada fames ac ante ipsum primis in
                                  faucibus. Sed ipsum libero, aliquet sed bibendum
                                  faucibus, semper a dui.</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-xs-12 col-sm-12 ">
                              <div id="col_inner_id-5f2243537bfc9" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                                <p>Cras commodo, nulla a commodo sodales, nisl mauris
                                  interdum lectus, ac mattis lacus purus ut nunc.
                                  Fusce volutpat aliquam euismod. Aliquam pulvinar
                                  neque turpis, in tincidunt mi varius et. Curabitur
                                  vitae tempus mauris, porta dictum ante. Nam
                                  pellentesque et mauris a suscipit. Vivamus gravida
                                  erat nec elit ullamcorper, quis laoreet metus
                                  efficitur. Duis vulputate, mauris a auctor pretium,
                                  elit nisl eleifend nulla, non accumsan augue massa
                                  quis tellus. Aliquam at justo libero. Orci varius
                                  natoque penatibus et magnis dis parturient montes,
                                  nascetur ridiculus mus. Sed vestibulum mauris
                                  condimentum ultrices scelerisque. Fusce pulvinar
                                  cursus luctus. Cras dapibus placerat magna, quis
                                  euismod nisi consequat euismod. Curabitur finibus
                                  nisi at justo ultricies, nec congue metus rutrum.
                                  Quisque vulputate sollicitudin aliquam. Curabitur
                                  posuere auctor dapibus.</p>
                                <p>Mauris lectus dolor, varius ut imperdiet nec,
                                  dignissim nec ligula. Cras posuere odio et finibus
                                  accumsan. Mauris in sem non arcu consectetur posuere
                                  sed quis justo. Sed turpis mauris, aliquet ac lacus
                                  nec, tempor condimentum justo. Praesent tristique
                                  enim et augue tincidunt, in accumsan nisi egestas.
                                  Cras bibendum elit eget volutpat consectetur.
                                  Vestibulum sit amet posuere velit, ut rhoncus lorem.
                                  Mauris sit amet ultricies justo. Curabitur ut
                                  placerat libero, ut tempus eros. Quisque venenatis
                                  eu mi eget imperdiet. Fusce vitae tellus dui.
                                  Curabitur tristique pharetra justo, vel posuere
                                  dolor luctus vel. Quisque in arcu sagittis,
                                  sollicitudin nulla in, aliquet turpis. Vestibulum
                                  ante ipsum primis in faucibus orci luctus et
                                  ultrices posuere cubilia Curae; Donec eros lorem,
                                  euismod ut nulla ut, congue molestie elit. Quisque
                                  ex augue, finibus ut fermentum vel, molestie eget
                                  nulla.</p>
                                <blockquote className="quote fw-quote-left fw-quote-lg ">
                                  <p>Phasellus volutpat congue augue, a tincidunt
                                    velit consequat pulvinar. Proin pharetra, tortor
                                    sed efficitur egestas, massa lorem eleifend
                                    tortor, eget interdum dolor neque vitae dui.
                                    Etiam vitae pharetra justo. Sed dictum vulputate
                                    cursus. Nulla consectetur purus in interdum
                                    interdum.</p>
                                  <footer className="quote-author">
                                    <span>
                                      John T. Talley </span>
                                  </footer>
                                </blockquote>
                                <p>Integer dapibus sapien massa, in mattis magna commodo
                                  id. Vivamus tempor ullamcorper auctor. Aenean non
                                  bibendum magna, quis tempor ligula. Donec viverra
                                  lobortis libero, non feugiat nulla vehicula id. Ut
                                  posuere tellus in gravida ullamcorper. Nulla ut
                                  pellentesque velit. Mauris scelerisque auctor
                                  convallis. Sed tincidunt finibus est, nec convallis
                                  sem placerat eget. Donec tempor mauris vitae libero
                                  ultrices, a porta ex commodo. Quisque quis magna
                                  elit. Proin semper tellus quis orci tempor,
                                  porttitor lobortis nisi auctor. Phasellus turpis
                                  diam, commodo vel sagittis ac, lacinia vitae ex.
                                  Integer lacinia dapibus euismod. Integer bibendum
                                  nulla nec tincidunt consequat. Sed vel gravida
                                  risus. Cras lacinia tortor purus, vel vulputate
                                  velit rhoncus sed.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>{/* .entry-content */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T08:11:44+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">design</a><a href="#/" rel="tag">menu</a><a href="#/" rel="tag">ui</a><a href="#/" rel="tag">video</a><a href="#/" rel="tag">youtube</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <nav className="navigation post-navigation clearfix">
                <div className="nav-links">
                  <a href="#/" rel="prev"><span className="meta-nav"> Why I Switched to Sketch For UI
                      Design</span></a><a href="#/" rel="next"><span className="meta-nav"> 6 Easy Steps to Better Icon
                      Design</span></a>
                </div>
                {/* .nav-links */}
              </nav>{/* .navigation */}
            </div>{/* #content */}
          </div>{/* #primary */}
        </div>{/* #main-content */}
      </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}