import React from 'react';
import Offline from '../Offline';
import ConnectionChange from '../../lib/connection_change';

export default class Blog_Post_3 extends React.Component{

  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidMount(){
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    window.$("body").addClass("masthead-fixed list-view full-width singular");
  }
  
  render(){
    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div id="content" className="page-content site-content" role="main">
              <article id="post-120" className="post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-e-commerce tag-cms tag-e-commerce">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">E-Commerce</a></span>
                  </div>{/* .entry-meta */}
                  <h2 className="entry-title">An Overview of E-Commerce Platforms</h2>
                </header>{/* .entry-header */}
                <div className="post-thumbnail">
                  <img loading="lazy" src="/assets/img/blog/blog_post_3_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </div>
                <div className="post-content">
                  <div className="entry-content">
                    <div className="fw-page-builder-content">
                      <section className="fw-main-row  ">
                        <div className="fw-container">
                          <div className="row">
                            <div className=" col-xs-12 col-sm-12 ">
                              <div id="col_inner_id-5f2243ce0e3a6" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                                <p>Ut vel tincidunt felis. Morbi semper ullamcorper
                                  quam, mollis elementum velit. Quisque quis mauris
                                  vitae sapien malesuada fermentum et ac neque. Mauris
                                  vulputate ut urna eu molestie. Proin id cursus
                                  turpis. Aenean ullamcorper urna a quam finibus
                                  porta. Praesent scelerisque sagittis lectus. Sed
                                  imperdiet lectus eget ex pellentesque aliquet.
                                  Aenean pellentesque magna sit amet diam rhoncus
                                  tempus. Vestibulum quis augue eu elit suscipit
                                  accumsan eu mattis ligula. Vivamus venenatis lacus
                                  at ultricies vulputate. Aliquam auctor pretium purus
                                  varius feugiat. Fusce sit amet urna leo. Vestibulum
                                  nec arcu id enim laoreet venenatis vel quis purus.
                                  Nulla congue, leo id semper sollicitudin, metus
                                  nulla mollis ex, id volutpat lorem eros et nunc.
                                  Suspendisse potenti.</p>
                                <blockquote className="quote fw-quote-left fw-quote-md ">
                                  <p>Etiam rutrum diam mi, eu tempor urna feugiat a.
                                    Maecenas luctus sem nec nisl congue, eget
                                    finibus justo bibendum. In id commodo enim.</p>
                                  <footer className="quote-author">
                                    <span>
                                      Craig W. Grant </span>
                                  </footer>
                                </blockquote>
                                <p>Class aptent taciti sociosqu ad litora torquent per
                                  conubia nostra, per inceptos himenaeos. Interdum et
                                  malesuada fames ac ante ipsum primis in faucibus.
                                  Curabitur mi ligula, sagittis et metus eu,
                                  scelerisque consequat lectus.</p>
                                <p>Etiam interdum vulputate risus, vitae elementum neque
                                  consectetur sed. Donec at risus dui. Ut in suscipit
                                  neque. Vestibulum sit amet lobortis magna, commodo
                                  venenatis ante. Cras molestie, ex a auctor lacinia,
                                  risus est aliquam risus, sit amet semper purus
                                  tortor id ante. Donec lacus ipsum, porttitor et
                                  libero a, fringilla auctor quam. Sed in nisl id
                                  libero tincidunt aliquet. Aenean dui ipsum, auctor
                                  ut leo ut, semper dignissim lacus. Suspendisse
                                  faucibus viverra consequat. Maecenas efficitur massa
                                  vel eros sagittis dapibus. Nam lobortis mi in turpis
                                  hendrerit eleifend. Nulla non massa felis.</p>
                                <p>Donec sit amet dolor ante. Vivamus vel massa
                                  accumsan, faucibus quam quis, convallis velit.
                                  Aliquam erat volutpat. Integer imperdiet diam quis
                                  arcu venenatis, quis sagittis nibh rhoncus. Donec
                                  non nisi scelerisque, sodales metus quis, accumsan
                                  mauris. Curabitur volutpat risus rutrum erat
                                  condimentum tristique. Nullam at felis diam. Quisque
                                  dictum felis non ante pretium mollis. Aliquam turpis
                                  neque, varius nec diam a, aliquam pulvinar diam.
                                  Interdum et malesuada fames ac ante ipsum primis in
                                  faucibus. Sed ipsum libero, aliquet sed bibendum
                                  faucibus, semper a dui.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>{/* .entry-content */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T07:08:39+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">cms</a><a href="#/" rel="tag">e-commerce</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <nav className="navigation post-navigation clearfix">
                <div className="nav-links">
                  <a href="#/" rel="prev"><span className="meta-nav"> Designing the Perfect Feature Comparison
                      Table</span></a><a href="#/" rel="next"><span className="meta-nav"> Why I Switched to Sketch For UI
                      Design</span></a> </div>
                {/* .nav-links */}
              </nav>{/* .navigation */}
            </div>{/* #content */}
          </div>{/* #primary */}
        </div>{/* #main-content */}
      </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}