import React from 'react';
import {Link} from 'react-router-dom';
import Offline from './Offline';
import ConnectionChange from '../lib/connection_change';

export default class Portfolio extends React.Component{

  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidMount(){
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    var e = window.$(".portfolio-grid"),
        a = window.$("#portfolio-gallery-grid");
    window.imagesLoaded(a,function () {
        a.masonry()
    });
    window.imagesLoaded(e,function () {
      window.$(".portfolio-content").each(function () {
          window.$(this);
          var e = window.$(this).attr("id"),
              a = window.$("#" + e + " .portfolio-grid"),
              t = window.$("#" + e + " .portfolio-filters"),
              o = window.$("#" + e + " .portfolio-filters .filter");
              a.shuffle({
                  speed: 450,
                  itemSelector: "figure"
              });
          window.$(".site-auto-menu").on("click", "a", function (e) {
              a.shuffle("update")
          });
          t.on("click", ".filter", function (e) {
              a.shuffle("update");
              e.preventDefault();
              o.parent().removeClass("active"); 
              window.$(this).parent().addClass("active"); 
              a.shuffle("shuffle", window.$(this).attr("data-group"));
          })
      })
    });

  }

  render(){
    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div className="page-title">
              <h1>Portfolio</h1>
              <div className="page-subtitle">
                <h4> My Works</h4>
              </div>
            </div>
            <div id="content" className="page-content site-content single-post" role="main">
              <div className="row">
                <div className=" col-xs-12 col-sm-12 ">
                  {/* Portfolio Content */}
                  <div id="portfolio_content_q" className="portfolio-content">
                    <ul className="portfolio-filters">
                      <li className="active">
                        <a href="#/" className="filter btn btn-sm btn-link" data-group="category_all">All</a>
                      </li>
                      <li>
                        <a href="#/" className="filter btn btn-sm btn-link" data-group="category_detailed">Detailed</a>
                      </li>
                      <li>
                        <a href="#/" className="filter btn btn-sm btn-link" data-group="category_direct-url">Direct URL</a>
                      </li>
                      <li>
                        <a href="#/" className="filter btn btn-sm btn-link" data-group="category_image">Image</a>
                      </li>
                      <li>
                        <a href="#/" className="filter btn btn-sm btn-link" data-group="category_soundcloud">SoundCloud</a>
                      </li>
                      <li>
                        <a href="#/" className="filter btn btn-sm btn-link" data-group="category_video">Video</a>
                      </li>
                      <li>
                        <a href="#/" className="filter btn btn-sm btn-link" data-group="category_vimeo-video">Vimeo Video</a>
                      </li>
                      <li>
                        <a href="#/" className="filter btn btn-sm btn-link" data-group="category_youtube-video">YouTube Video</a>
                      </li>
                    </ul>
                    {/* Portfolio Grid */}
                    <div className="portfolio-grid three-columns shuffle">
                      {/* Portfolio Item 1 */}
                      <figure className="item standard" data-groups="[&quot;category_all&quot;, &quot;category_detailed&quot;]">
                        <div className="portfolio-item-img">
                          <img loading="lazy" src="/assets/img/portfolio/1.jpg" alt="Full Project 2" title />
                          <Link to="/portfolio/portfolio-project-2" className="ajax-page-load" />
                        </div>
                        <i className="far fa-file-alt" />
                        <h4 className="name">Full Project 2</h4>
                        <span className="category">Detailed</span>
                      </figure>
                      {/* /Portfolio Item 1 */}
                      {/* Portfolio Item 2 */}
                      <figure className="item lbvideo" data-groups="[&quot;category_all&quot;, &quot;category_video&quot;, &quot;category_youtube-video&quot;]">
                        <div className="portfolio-item-img">
                          <img loading="lazy" src="/assets/img/portfolio/2.jpg" alt="YouTube Video 2" title />
                          <a href="https://www.youtube.com/embed/F-1weFCiYBA" className="lightbox mfp-iframe" title={"YouTube Video 2"} />
                        </div>
                        <i className="fab fa-youtube" />
                        <h4 className="name">YouTube Video 2</h4>
                        <span className="category">Video, YouTube Video</span>
                      </figure>
                      {/* /Portfolio Item 2 */}
                      {/* Portfolio Item 3 */}
                      <figure className="item lbimage" data-groups="[&quot;category_all&quot;, &quot;category_image&quot;]">
                        <div className="portfolio-item-img">
                          <img loading="lazy" src="/assets/img/portfolio/3.jpg" alt="" title />
                          <a className="lightbox" title={"Image 2"} href="/assets/img/portfolio/full/3.jpg" />
                        </div>
                        <i className="fa fa-image" />
                        <h4 className="name">Image 2</h4>
                        <span className="category">Image</span>
                      </figure>
                      {/* /Portfolio Item 3 */}
                      {/* Portfolio Item 4 */}
                      <figure className="item lbaudio" data-groups="[&quot;category_all&quot;, &quot;category_soundcloud&quot;]">
                        <div className="portfolio-item-img">
                          <img loading="lazy" src="/assets/img/portfolio/4.jpg" alt="SoundCloud Audio 1" title />
                          <a href="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/221650664&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" className="lightbox mfp-iframe" title={"SoundCloud Audio 1"} />
                        </div>
                        <i className="fas fa-volume-up" />
                        <h4 className="name">SoundCloud Audio 1</h4>
                        <span className="category">SoundCloud</span>
                      </figure>
                      {/* /Portfolio Item 4 */}
                      {/* Portfolio Item 5 */}
                      <figure className="item lbvideo" data-groups="[&quot;category_all&quot;, &quot;category_video&quot;, &quot;category_vimeo-video&quot;]">
                        <div className="portfolio-item-img">
                          <img loading="lazy" src="/assets/img/portfolio/5.jpg" alt="Vimeo Video" title />
                          <a href="https://player.vimeo.com/video/158284739" className="lightbox mfp-iframe" title={"Vimeo Video"} />
                        </div>
                        <i className="fab fa-vimeo-v" />
                        <h4 className="name">Vimeo Video</h4>
                        <span className="category">Video, Vimeo Video</span>
                      </figure>
                      {/* /Portfolio Item 5 */}
                      {/* Portfolio Item 6 */}
                      <figure className="item lbvideo" data-groups="[&quot;category_all&quot;, &quot;category_video&quot;, &quot;category_youtube-video&quot;]">
                        <div className="portfolio-item-img">
                          <img loading="lazy" src="/assets/img/portfolio/6.jpg" alt="YouTube Video 1" title />
                          <a href="https://www.youtube.com/embed/ZK8REu-8pGw" className="lightbox mfp-iframe" title={"YouTube Video 1"} />
                        </div>
                        <i className="fab fa-youtube" />
                        <h4 className="name">YouTube Video 1</h4>
                        <span className="category">Video, YouTube Video</span>
                      </figure>
                      {/* /Portfolio Item 6 */}
                      {/* Portfolio Item 7 */}
                      <figure className="item standard" data-groups="[&quot;category_all&quot;, &quot;category_detailed&quot;]">
                        <div className="portfolio-item-img">
                          <img loading="lazy" src="/assets/img/portfolio/7.jpg" alt="Full Project 1" title />
                          <Link to="/portfolio/portfolio-project-1" className="ajax-page-load" />
                        </div>
                        <i className="far fa-file-alt" />
                        <h4 className="name">Full Project 1</h4>
                        <span className="category">Detailed</span>
                      </figure>
                      {/* /Portfolio Item 7 */}
                      {/* Portfolio Item 8 */}
                      <figure className="item direct" data-groups="[&quot;category_all&quot;, &quot;category_direct-url&quot;]">
                        <div className="portfolio-item-img">
                          <img loading="lazy" src="/assets/img/portfolio/8.jpg" alt="Direct URL" title />
                          <a target="_blank" href="#/" rel="noreferrer"  href="#/"/>
                        </div>
                        <i className="fa fa-link" />
                        <h4 className="name">Direct URL</h4>
                        <span className="category">Direct URL</span>
                      </figure>
                      {/* /Portfolio Item 8 */}
                      {/* Portfolio Item 9 */}
                      <figure className="item lbimage" data-groups="[&quot;category_all&quot;, &quot;category_image&quot;]">
                        <div className="portfolio-item-img">
                          <img loading="lazy" src="/assets/img/portfolio/9.jpg" alt="" title />
                          <a className="lightbox" title={"Image 1"} href="/assets/img/portfolio/full/5.jpg" />
                        </div>
                        <i className="fa fa-image" />
                        <h4 className="name">Image 1</h4>
                        <span className="category">Image</span>
                      </figure>
                      {/* /Portfolio Item 9 */}
                    </div>
                    {/* /Portfolio Content */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}