import React from 'react';
import Offline from '../Offline';
import ConnectionChange from '../../lib/connection_change';

export default class Blog_Post_4 extends React.Component{

  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidMount(){
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    window.$("body").addClass("masthead-fixed list-view full-width singular");
  }
  
  render(){
    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div id="content" className="page-content site-content" role="main">
              <article id="post-123" className="post-123 post type-post status-publish format-standard has-post-thumbnail hentry category-design tag-design tag-photoshop tag-sketch tag-soundcloud">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">Design</a></span>
                  </div>{/* .entry-meta */}
                  <h2 className="entry-title">Why I Switched to Sketch For UI Design</h2>
                </header>{/* .entry-header */}
                <div className="post-thumbnail">
                  <img loading="lazy" src="/assets/img/blog/blog_post_4_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </div>
                <div className="post-content">
                  <div className="entry-content">
                    <div className="fw-page-builder-content">
                      <section className="fw-main-row  ">
                        <div className="fw-container">
                          <div className="row">
                            <div className=" col-xs-12 col-sm-12 ">
                              <div className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                                <p>Integer dapibus sapien massa, in mattis magna commodo
                                  id. Vivamus tempor ullamcorper auctor. Aenean non
                                  bibendum magna, quis tempor ligula. Donec viverra
                                  lobortis libero, non feugiat nulla vehicula id. Ut
                                  posuere tellus in gravida ullamcorper. Nulla ut
                                  pellentesque velit. Mauris scelerisque auctor
                                  convallis. Sed tincidunt finibus est, nec convallis
                                  sem placerat eget. Donec tempor mauris vitae libero
                                  ultrices, a porta ex commodo. Quisque quis magna
                                  elit. Proin semper tellus quis orci tempor,
                                  porttitor lobortis nisi auctor. Phasellus turpis
                                  diam, commodo vel sagittis ac, lacinia vitae ex.
                                  Integer lacinia dapibus euismod. Integer bibendum
                                  nulla nec tincidunt consequat. Sed vel gravida
                                  risus. Cras lacinia tortor purus, vel vulputate
                                  velit rhoncus sed.</p>
                                <p>Maecenas id finibus felis. Etiam vitae nibh et felis
                                  efficitur pellentesque. Mauris suscipit sapien nunc,
                                  a lacinia nibh feugiat ut. In hac habitasse platea
                                  dictumst. Fusce eu finibus urna, sit amet ornare
                                  enim. Vivamus consectetur ex urna, sit amet
                                  venenatis risus tincidunt eu. In eu dapibus justo.
                                  Suspendisse pulvinar fringilla ante non vehicula.
                                  Suspendisse nec cursus orci. Donec enim augue,
                                  ultrices ornare laoreet sed, facilisis sit amet
                                  nunc. Vestibulum fringilla orci eu enim porttitor
                                  facilisis. Donec dapibus malesuada tortor, imperdiet
                                  dapibus quam tempor eu. Vivamus sed viverra augue.
                                </p>
                                <blockquote className="quote fw-quote-left fw-quote-md ">
                                  <p>Etiam ante dolor, imperdiet vitae eros at, varius
                                    euismod metus. Fusce augue magna, tincidunt sed
                                    viverra quis, ultrices sed urna. Etiam nec
                                    sodales lorem. Lorem ipsum dolor sit amet,
                                    consectetur adipiscing elit. Maecenas vitae
                                    finibus purus, vel porttitor nunc. Nunc massa
                                    enim, dignissim ac erat suscipit, maximus
                                    malesuada augue.</p>
                                  <footer className="quote-author">
                                    <span>
                                      Jeffery K. Perkins </span>
                                  </footer>
                                </blockquote>
                                <p>Nulla nulla nisl, sodales ac nulla ac, consequat
                                  vulputate purus. Curabitur tincidunt ipsum vel nibh
                                  rutrum accumsan. Nunc ullamcorper posuere leo, vitae
                                  aliquet risus pharetra in. Integer turpis eros,
                                  iaculis et mi non, pulvinar egestas leo. Etiam
                                  sagittis ex turpis, vitae cursus tortor interdum eu.
                                  Quisque ultrices nunc eget erat vestibulum euismod.
                                  Ut mauris nisi, facilisis at arcu nec, facilisis
                                  porttitor lorem. Vivamus vitae neque molestie, porta
                                  libero sed, tincidunt leo. In nec posuere odio, id
                                  rhoncus lorem. Proin id erat ut dolor condimentum
                                  viverra. Praesent viverra sed dolor ac luctus.
                                  Praesent placerat id lorem quis lacinia.</p>
                                <div className="single-image ">
                                  <a href="/assets/img/blog/blog_post_4_full.jpg" className="lightbox mfp-iframe">
                                    <img loading="lazy" src="/assets/img/blog/blog_post_4.jpg" alt="" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>{/* .entry-content */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T07:28:16+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">design</a><a href="#/" rel="tag">photoshop</a><a href="#/" rel="tag">sketch</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <nav className="navigation post-navigation clearfix">
                <div className="nav-links">
                  <a href="#/" rel="prev"><span className="meta-nav"> An Overview of E-Commerce
                      Platforms</span></a><a href="#/" rel="next"><span className="meta-nav"> Creative and Innovative Navigation
                      Designs</span></a> </div>
                {/* .nav-links */}
              </nav>{/* .navigation */}
            </div>{/* #content */}
          </div>{/* #primary */}
        </div>{/* #main-content */}
      </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}