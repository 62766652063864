import React from 'react';
import Offline from '../Offline';
import ConnectionChange from '../../lib/connection_change';

export default class Blog_Post_5 extends React.Component{

  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidMount(){
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    window.$("body").addClass("masthead-fixed list-view full-width singular");
  }
  
  render(){
    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div id="content" className="page-content site-content" role="main">
              <article id="post-136" className="post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-design tag-design tag-icon tag-ui tag-ux">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">Design</a></span>
                  </div>{/* .entry-meta */}
                  <h2 className="entry-title">6 Easy Steps to Better Icon Design</h2>
                </header>{/* .entry-header */}
                <div className="post-thumbnail">
                  <img loading="lazy" src="/assets/img/blog/blog_post_5_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </div>
                <div className="post-content">
                  <div className="entry-content">
                    <div className="fw-page-builder-content">
                      <section className="fw-main-row  ">
                        <div className="fw-container">
                          <div className="row">
                            <div className=" col-xs-12 col-sm-12 ">
                              <div id="col_inner_id-5f22430f71e79" className="fw-col-inner" data-paddings="0px 0px 0px 0px">
                                <p>hasellus volutpat congue augue, a tincidunt velit
                                  consequat pulvinar. Proin pharetra, tortor sed
                                  efficitur egestas, massa lorem eleifend tortor, eget
                                  interdum dolor neque vitae dui. Etiam vitae pharetra
                                  justo. Sed dictum vulputate cursus. Nulla
                                  consectetur purus in interdum interdum. Donec ornare
                                  dui mi, vitae consectetur ex tempor ut. Aliquam
                                  ultricies tristique arcu, laoreet elementum nunc
                                  tristique ac. Mauris condimentum hendrerit neque sit
                                  amet egestas. Vivamus lacus ante, pharetra in
                                  placerat eget, vehicula eget turpis. Quisque auctor
                                  leo tortor, quis porttitor metus condimentum nec.
                                </p>
                                <p>Pellentesque semper orci enim, at dapibus arcu
                                  fringilla vitae. Praesent sit amet consequat lorem.
                                  Sed elit odio, aliquet eget elementum sit amet,
                                  sodales ut massa. Phasellus eget velit facilisis,
                                  viverra urna id, finibus est. Pellentesque convallis
                                  et elit a faucibus. Suspendisse luctus mollis porta.
                                  Morbi hendrerit ex turpis, sed consectetur nunc
                                  lacinia nec. Praesent vitae viverra tellus, a
                                  pretium velit.</p>
                                <div className="single-image ">
                                  <a href="/assets/img/blog/blog_post_5_full.jpg" className="lightbox mfp-iframe">
                                    <img loading="lazy" src="/assets/img/blog/blog_post_5.jpg" alt="" />
                                  </a>
                                </div>
                                <p>Ut vel tincidunt felis. Morbi semper ullamcorper
                                  quam, mollis elementum velit. Quisque quis mauris
                                  vitae sapien malesuada fermentum et ac neque. Mauris
                                  vulputate ut urna eu molestie. Proin id cursus
                                  turpis. Aenean ullamcorper urna a quam finibus
                                  porta. Praesent scelerisque sagittis lectus. Sed
                                  imperdiet lectus eget ex pellentesque aliquet.
                                  Aenean pellentesque magna sit amet diam rhoncus
                                  tempus. Vestibulum quis augue eu elit suscipit
                                  accumsan eu mattis ligula. Vivamus venenatis lacus
                                  at ultricies vulputate. Aliquam auctor pretium purus
                                  varius feugiat. Fusce sit amet urna leo. Vestibulum
                                  nec arcu id enim laoreet venenatis vel quis purus.
                                  Nulla congue, leo id semper sollicitudin, metus
                                  nulla mollis ex, id volutpat lorem eros et nunc.
                                  Suspendisse potenti.</p>
                                <blockquote className="quote fw-quote-left fw-quote-md ">
                                  <p>In non arcu turpis. Pellentesque ornare tortor at
                                    condimentum pulvinar. Vivamus ultrices nisi sed
                                    est scelerisque, vel suscipit libero luctus.
                                    Cras magna nulla, suscipit in vulputate a,
                                    consequat egestas sem. Ut commodo eget velit nec
                                    egestas. Donec porta lorem vel neque varius
                                    aliquet. Nam convallis lacus ac lectus posuere
                                    bibendum.</p>
                                  <footer className="quote-author">
                                    <span>
                                      John G. Koss </span>
                                  </footer>
                                </blockquote>
                                <p>Donec elementum dui vitae vulputate congue. Sed sed
                                  semper erat. Mauris molestie, sem et imperdiet
                                  sodales, tellus massa mattis mauris, sit amet
                                  facilisis dolor lacus sed orci. Sed aliquam placerat
                                  viverra. Etiam commodo libero felis, eget hendrerit
                                  nibh pharetra ac. Vestibulum turpis est, vestibulum
                                  at neque id, interdum vulputate lorem. Phasellus
                                  lobortis vulputate varius. Donec tincidunt massa id
                                  tempus interdum. Etiam id sem sit amet mauris
                                  consectetur vulputate quis in libero. In interdum
                                  enim tempus, egestas dolor bibendum, condimentum
                                  dui. Pellentesque nec erat orci. Duis neque dui,
                                  posuere mattis risus eu, dictum hendrerit velit.
                                  Vivamus iaculis lectus ut consectetur fringilla.</p>
                                <p>Etiam rutrum diam mi, eu tempor urna feugiat a.
                                  Maecenas luctus sem nec nisl congue, eget finibus
                                  justo bibendum. In id commodo enim. Donec vehicula
                                  interdum tortor, laoreet pretium erat ultrices sed.
                                  Donec id aliquam tortor. Etiam pellentesque diam a
                                  magna rutrum placerat. Class aptent taciti sociosqu
                                  ad litora torquent per conubia nostra, per inceptos
                                  himenaeos. Interdum et malesuada fames ac ante ipsum
                                  primis in faucibus. Curabitur mi ligula, sagittis et
                                  metus eu, scelerisque consequat lectus.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>{/* .entry-content */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T08:24:52+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">design</a><a href="#/" rel="tag">icon</a><a href="#/" rel="tag">ui</a><a href="#/" rel="tag">ux</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <nav className="navigation post-navigation clearfix">
                <div className="nav-links">
                  <a href="#/" rel="prev"><span className="meta-nav"> Creative and Innovative Navigation
                      Designs</span></a><a href="#/" rel="next"><span className="meta-nav"> How to Make a WordPress Plugin
                      Extensible</span></a> </div>
                {/* .nav-links */}
              </nav>{/* .navigation */}
            </div>{/* #content */}
          </div>{/* #primary */}
        </div>{/* #main-content */}
      </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}