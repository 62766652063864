import React from 'react';
import Offline from './Offline';
import ConnectionChange from '../lib/connection_change';

export default class Sponsor extends React.Component{
  
  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  render(){

    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div className="page-title">
              <h1>Sponsorship</h1>
              <div className="page-subtitle">
                <h4>Sponsor me if you like my works.</h4>
              </div>
            </div>
            <div id="content" className="page-content site-content single-post" role="main">
              {/* Pricing */}
              <div className="row">
                <div className=" col-xs-12 col-sm-12 ">
                  <div className="block-title">
                    <h2>Pricing</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12 ">
                  <div className="fw-pricing clearfix row">
                    <div className="fw-package-wrap col-md-4 ">
                      <div className="fw-package">
                        <div className="fw-heading-row">
                          <span>Silver</span>
                        </div>
                        <div className="fw-pricing-row">
                          <span>$3</span>
                          <small>per month</small>
                        </div>
                        <div className="fw-button-row">
                          <a href="https://www.paypal.com/paypalme/rajarshikundu5/usd3" rel="noreferrer" target="_blank" className="btn btn-secondary">Sponsor</a>
                        </div>
                        <div className="fw-default-row">This can get me:</div>
                        <div className="fw-default-row">Motivation to work</div>
                        <div className="fw-default-row">One bottle Beer</div>
                      </div>
                    </div>
                    <div className="fw-package-wrap col-md-4 highlight-col ">
                      <div className="fw-package">
                        <div className="fw-heading-row">
                          <span>Gold</span>
                        </div>
                        <div className="fw-pricing-row">
                          <span>$15</span>
                          <small>one time</small>
                        </div>
                        <div className="fw-button-row">
                          <a href="https://www.paypal.com/paypalme/rajarshikundu5/usd15" rel="noreferrer" target="_blank" className="btn btn-primary">Donate Now</a>
                        </div>
                        <div className="fw-default-row">This can get me:</div>
                        <div className="fw-default-row" />
                        <div className="fw-default-row">One month Internet bill</div>
                        <div className="fw-default-row">A Sun Glass</div>
                        <div className="fw-default-row">
                        </div>
                      </div>
                    </div>
                    <div className="fw-package-wrap col-md-4 default-col ">
                      <div className="fw-package">
                        <div className="fw-heading-row">
                          <span>Platinum</span>
                        </div>
                        <div className="fw-pricing-row">
                          <span>$150</span>
                          <small>one time</small>
                        </div>
                        <div className="fw-button-row">
                          <a href="https://www.paypal.com/paypalme/rajarshikundu5/usd150" rel="noreferrer" target="_blank" className="btn btn-primary">Donate Now</a>
                        </div>
                        <div className="fw-default-row">This can get me:</div>
                        <div className="fw-default-row">One Month Electricity Bill</div>
                        <div className="fw-default-row">A Luxurious Dinner</div>
                        <div className="fw-default-row">A dual Bass Headset</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Pricing */}
              </div>
            </div>{/* #content */}
          </div>{/* #primary */}
        </div>{/* #main-content */}
      </div>
    );
  }
else{
  return(<Offline/>);
}
  }
}