import React from 'react';
import {Link} from 'react-router-dom';
import Offline from './Offline';
import ConnectionChange from '../lib/connection_change';

export default class Portfolio_project_1 extends React.Component{
  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  render(){
    if(this.state.isConnected){
    return(
      <div id="ajax-page" className="ajax-page-content">
        <div className="ajax-page-wrapper">
          <div className="ajax-page-nav">
            <div className="nav-item ajax-page-prev-next">
              <Link className="ajax-page-load" to="/portfolio/portfolio-project-3"><i className="lnr lnr-chevron-left" /></Link>
              <Link className="ajax-page-load" to="/portfolio/portfolio-project-2"><i className="lnr lnr-chevron-right"/></Link>
            </div>
            <div className="nav-item ajax-page-close-button">
              <Link id="ajax-page-close-button" to="/portfolio"><i className="lnr lnr-cross" /></Link>
            </div>
          </div>
          <div className="ajax-page-title">
            <h1>Portfolio Project 1</h1>
          </div>
          <div className="row">
            <div className="col-sm-8 col-md-8 portfolio-block">

              <div className="portfolio-page-video embed-responsive embed-responsive-16by9">
                <iframe loading="lazy" className="embed-responsive-item" src="https://player.vimeo.com/video/97102654?autoplay=0" title="video" />
              </div>
            </div>
            <div className="col-sm-4 col-md-4 portfolio-block">
              {/* Project Description */}
              <div className="project-description">
                <div className="block-title">
                  <h3>Description</h3>
                </div>
                <ul className="project-general-info">
                  <li><p><i className="fa fa-user" /> Elanza-48</p></li>
                  <li><p><i className="fa fa-globe" /> <a href="#/" rel="noreferrer" target="_blank">www.project-site.com</a></p></li>
                  <li><p><i className="fa fa-calendar" /> 25 december, 2016</p></li>
                </ul>
                <p className="text-justify">Aliquam euismod aliquam massa, quis eleifend dui sodales vitae. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                {/* /Project Description */}
                {/* Technology */}
                <div className="tags-block">
                  <div className="block-title">
                    <h3>Technology</h3>
                  </div>
                  <ul className="tags">
                    <li><a href="#/">HTML5</a></li>
                    <li><a href="#/">CSS3</a></li>
                    <li><a href="#/">jQuery</a></li>
                    <li><a href="#/">Ajax</a></li>
                    <li><a href="#/">PHP5</a></li>
                  </ul>
                </div>
                {/* /Technology */}
                {/* Share Buttons */}
                <div className="btn-group share-buttons">
                  <div className="block-title">
                    <h3>Share</h3>
                  </div>
                  <a href="#/" rel="noreferrer" target="_blank" className="btn"><i className="fab fa-facebook-f" /> </a>
                  <a href="#/" rel="noreferrer" target="_blank" className="btn"><i className="fab fa-twitter" /> </a>
                  <a href="#/" rel="noreferrer" target="_blank" className="btn"><i className="fab fa-dribbble" /> </a>
                </div>
                {/* /Share Buttons */}
              </div>
              {/* Project Description */}
            </div>
          </div>
        </div>
      </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}