import React from 'react';
import {Link} from 'react-router-dom';

class About extends React.Component{

  componentDidMount(){
    window.$(".testimonials.owl-carousel").owlCarousel({
      nav: !1,
      items: 3,
      loop: !1,
      navText: !1,
      margin: 25,
      responsive: {
          0: {
              items: 1
          },
          480: {
              items: 1
          },
          768: {
              items: 2
          },
          1200: {
              items: 2
          }
      }
    }); 
    window.imagesLoaded(
      window.$(".clients.owl-carousel"),function(){
        window.$(".clients.owl-carousel").owlCarousel({
          nav: !1,
          items: 2,
          loop: !1,
          navText: !1,
          margin: 10,
          autoHeight: !1,
          responsive: {
              0: {
                  items: 2
              },
              768: {
                  items: 4
              },
              1200: {
                  items: 6
              }
          }
        })
    });
    window.$(".text-rotation").owlCarousel({
      loop: !0,
      dots: !1,
      nav: !1,
      margin: 10,
      items: 1,
      autoplay: !0,
      autoplayHoverPause: !1,
      autoplayTimeout: 3800,
      animateOut: "fadeOut",
      animateIn: "fadeIn"
    });
  }
  render(){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">    
            <div id="content" className="page-content site-content single-post" role="main">
              {/* Home Page Top Part */}
              <div className="row">
                <div className=" col-xs-12 col-sm-12">
                  <div className="home-content">
                    <div className="row flex-v-align">
                      <div className="col-sm-12 col-md-5 col-lg-5">
                        <div className="home-photo">
                          <div className="hp-inner">
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7 col-lg-7">
                        <div className="home-text hp-left">
                          <div className="owl-carousel text-rotation">                                    
                            <div className="item">
                              <h4>Backend-developer</h4>
                            </div>
                            <div className="item">
                              <h4>Web Designer</h4>
                            </div>
                          </div>
                          <h1>Rajarshi Kundu</h1>
                          <p> I am a freelance developer as well as a student. I create clarifying strategy in Java and Mobile Applications, Data Mining and Analysis Strategies, Backend solutions and System design patterns for small and Medium scale sectors.</p>
                          <div className="home-buttons">
                            <Link to="/resume" className="btn btn-primary">Download CV</Link>
                            <Link to="/contact" target="_self" className="btn btn-secondary">Contact</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Home Page Top Part */}
              {/* Services */}
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <div className="p-50" />
                  <div className="block-title">
                    <h2>What I Do</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <div className="info-list-w-icon">
                    <div className="info-block-w-icon">
                      <div className="ci-icon">
                        <i className="linecons linecons-pen" />
                      </div>
                      <div className="ci-text">
                        <h4>Coding</h4>
                        <p>Mauris neque libero, aliquet vel mollis nec, euismod sed tellus. Mauris convallis dictum elit id volutpat. Vivamus blandit, dolor vitae lacinia maximus, risus velit vehicula odio, a tincidunt turpis turpis tempus ex.</p>
                      </div>
                    </div>
                    <div className="info-block-w-icon">
                      <div className="ci-icon">
                        <i className="linecons linecons-display" />
                      </div>
                      <div className="ci-text">
                        <h4>Web Design</h4>
                        <p>Mauris neque libero, aliquet vel mollis nec, euismod sed tellus. Mauris convallis dictum elit id volutpat. Vivamus blandit, dolor vitae lacinia maximus, risus velit vehicula odio, a tincidunt turpis turpis tempus ex.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="info-list-w-icon">
                    <div className="info-block-w-icon">
                      <div className="ci-icon">
                        <i className="linecons linecons-shop" />
                      </div>
                      <div className="ci-text">
                        <h4>System Design</h4>
                        <p>Mauris neque libero, aliquet vel mollis nec, euismod sed tellus. Mauris convallis dictum elit id volutpat. Vivamus blandit, dolor vitae lacinia maximus, risus velit vehicula odio, a tincidunt turpis turpis tempus ex.</p>
                      </div>
                    </div>
                    <div className="info-block-w-icon">
                      <div className="ci-icon">
                        <i className="linecons linecons-megaphone" />
                      </div>
                      <div className="ci-text">
                        <h4>Blogging</h4>
                        <p>Mauris neque libero, aliquet vel mollis nec, euismod sed tellus. Mauris convallis dictum elit id volutpat. Vivamus blandit, dolor vitae lacinia maximus, risus velit vehicula odio, a tincidunt turpis turpis tempus ex.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Services */}
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <div className="p-20" />
                  {/* Testimonials */}
                  <div className="block-title">
                    <h2>Testimonials</h2>
                  </div>
                  <div id="testimonials_1" className="testimonials owl-carousel" data-mobile-items={1} data-tablet-items={2} data-items={2}>
                    {/* Testimonial 1 */}
                    <div className="testimonial-item">
                      {/* Testimonial Content */}
                      <div className="testimonial-content">
                        {/* Picture */}
                        <div className="testimonial-picture">
                          <img loading="lazy" src="/assets/img/testimonials/testimonial-1.jpg" alt="Billy Adams" />
                        </div>              
                        {/* /Picture */}
                        {/* Testimonial Text */}
                        <div className="testimonial-text">
                          <p>Donec eu est vel metus consequat volutpat. Nunc aliquet euismod mauris, a feugiat urna ullamcorper non.</p>
                        </div>
                        {/* /Testimonial Text */}
                        {/* Testimonial author information */}
                        <div className="testimonial-author-info">
                          <h5 className="testimonial-author">Billy Adams</h5>
                          <p className="testimonial-firm">Rolling Thunder</p>
                        </div>
                        {/* /Testimonial author information */}
                        <div className="testimonial-icon">
                          <i className="fa fa-quote-left" />
                        </div>
                        <div className="testimonial-icon-big">
                          <i className="fa fa-quote-right" />
                        </div>
                      </div>
                      {/* /Testimonial Content */}
                    </div>
                    {/* End of Testimonial 1 */}
                    {/* Testimonial 2 */}
                    <div className="testimonial-item">
                      {/* Testimonial Content */}
                      <div className="testimonial-content">
                        {/* Picture */}
                        <div className="testimonial-picture">
                          <img loading="lazy" src="/assets/img/testimonials/testimonial-2.jpg" alt="Gary Johnson" />
                        </div>              
                        {/* /Picture */}
                        {/* Testimonial Text */}
                        <div className="testimonial-text">
                          <p>Nam tempor commodo mi id sodales. Aenean sit amet nibh nec sapien consequat porta a sit amet diam.</p>
                        </div>
                        {/* /Testimonial Text */}
                        {/* Testimonial author information */}
                        <div className="testimonial-author-info">
                          <h5 className="testimonial-author">Gary Johnson</h5>
                          <p className="testimonial-firm">Locost Accessories</p>
                        </div>
                        {/* /Testimonial author information */}
                        <div className="testimonial-icon">
                          <i className="fa fa-quote-left" />
                        </div>
                        <div className="testimonial-icon-big">
                          <i className="fa fa-quote-right" />
                        </div>
                      </div>
                      {/* /Testimonial Content */}
                    </div>
                    {/* End of Testimonial 2 */}
                    {/* End of Testimonial 3 */}
                    <div className="testimonial-item">
                      {/* Testimonial Content */}
                      <div className="testimonial-content">
                        {/* Picture */}
                        <div className="testimonial-picture">
                          <img loading="lazy" src="/assets/img/testimonials/testimonial-3.jpg" alt="Daniel Pringle" />
                        </div>              
                        {/* /Picture */}
                        {/* Testimonial Text */}
                        <div className="testimonial-text">
                          <p>Etiam pretium ipsum quis justo dictum accumsan. Phasellus egestas odio a velit scelerisque.</p>
                        </div>
                        {/* /Testimonial Text */}
                        {/* Testimonial author information */}
                        <div className="testimonial-author-info">
                          <h5 className="testimonial-author">Daniel Pringle</h5>
                          <p className="testimonial-firm">Sagebrush</p>
                        </div>
                        {/* /Testimonial author information */}
                        <div className="testimonial-icon">
                          <i className="fa fa-quote-left" />
                        </div>
                        <div className="testimonial-icon-big">
                          <i className="fa fa-quote-right" />
                        </div>
                      </div>
                      {/* /Testimonial Content */}
                    </div>
                    {/* End of Testimonial 3 */}
                  </div>
                  {/* /Testimonials */}
                  <div className="p-40" />
                  {/* Clients Slider */}
                  <div className="block-title">
                    <h2>Clients</h2>
                  </div>
                  <div id="clients_1" className="clients owl-carousel" data-mobile-items={1} data-tablet-items={3} data-items={6}>
                    <div className="client-block">
                      <a href="/#" target="_blank" title="Golden Grid">
                        <img loading="lazy" src="/assets/img/clients/client-1.png" alt="Golden Grid" />
                      </a>
                    </div>
                    <div className="client-block">
                      <a href="/#" target="_blank" title="Sweety">
                        <img loading="lazy" src="/assets/img/clients/client-2.png" alt="Sweety" />
                      </a>
                    </div>
                    <div className="client-block">
                      <a href="/#" target="_blank" title="Magic Furnitures">
                        <img loading="lazy" src="/assets/img/clients/client-3.png" alt="Magic Furnitures" />
                      </a>
                    </div>
                    <div className="client-block">
                      <a href="/#" target="_blank" title="LifeGuard">
                        <img loading="lazy" src="/assets/img/clients/client-4.png" alt="LifeGuard" />
                      </a>
                    </div>
                    <div className="client-block">
                      <a href="/#" target="_blank" title="LESS">
                        <img loading="lazy" src="/assets/img/clients/client-5.png" alt="LESS" />
                      </a>
                    </div>
                    <div className="client-block">
                      <a href="/#" target="_blank" title="Design Lovers">
                        <img loading="lazy" src="/assets/img/clients/client-6.png" alt="Design Lovers" />
                      </a>
                    </div>
                    <div className="client-block">
                      <a href="/#" target="_blank" title="WordPress">
                        <img loading="lazy" src="/assets/img/clients/client-7.png" alt="WordPress" />
                      </a>
                    </div>
                  </div>
                  {/* /Clients Slider */}
                  <div className="p-40" />
                </div>
              </div>
              {/* Fun Facts */}
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <div className="block-title">
                    <h2>Fun Facts</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-3">
                  <div className="lm-info-block gray-default">
                    <i className="linecons linecons-heart" />
                    <h4>Happy Clients</h4>
                    <span className="lm-info-block-value">78</span>
                    <span className="lm-info-block-text" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="lm-info-block gray-default">
                    <i className="linecons linecons-clock" />
                    <h4>Working Hours</h4>
                    <span className="lm-info-block-value">4,780</span>
                    <span className="lm-info-block-text" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3 ">
                  <div className="lm-info-block gray-default">
                    <i className="linecons linecons-star" />
                    <h4>Awards Won</h4>
                    <span className="lm-info-block-value">8</span>
                    <span className="lm-info-block-text" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3 ">
                  <div className="lm-info-block gray-default">
                    <i className="linecons linecons-cup" />
                    <h4>Coffee Consumed</h4>
                    <span className="lm-info-block-value">1,286</span>
                    <span className="lm-info-block-text" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );};
}

export default About;