import React from 'react';

export default class Offline extends React.Component{

  componentDidMount(){
    window.$('#reloader').on('click', e=> {
      window.location.reload();
  });
  }
  render(){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content nothing-found">
          <div id="primary" className="content-area">
            <div id="content" className="site-content" role="main">
              <div className="page-content">
                <h1 className="nf-page-title offline">Offline !</h1>
                <p>It seems you are offline. Go online to view this page.</p>
                <div className="fw-button-row singular">
                  <a id="reloader" href="#/" className="btn btn-primary">Reload Now</a>
                </div>
              </div>{/* .page-content */}
            </div>{/* #content */}
          </div>
        </div>
      </div>
    );
  }
}