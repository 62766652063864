import React from 'react';
import {Link} from 'react-router-dom';

export default class NotFound extends React.Component{
  render(){
    return(
      <div id="main" className="site-main">
      <div id="main-content" className="single-page-content nothing-found">
        <div id="primary" className="content-area">
          <div id="content" className="site-content" role="main">
            <div className="page-content">
              <h1 className="nf-page-title">404</h1>
              <p>It looks like nothing was found at this location.</p>
              <div className="fw-button-row singular">
                <Link to="/" className="btn btn-primary">Goto Homepage</Link>
              </div>
            </div>{/* .page-content */}
          </div>{/* #content */}
        </div>
      </div>
    </div>
    );
  }
}