export function enableTheme(newTheme = 'light',save = false){
  let theme= newTheme;

  if(theme === 'light'){
    window.$("body").removeClass('dark-mode');
    window.$('.menu-theme-toggle > i').removeClass('fa-sun');
    window.$('.menu-theme-toggle > i').addClass('fa-moon');
  }else if(theme === 'dark'){
    window.$("body").addClass('dark-mode');
    window.$('.menu-theme-toggle > i').removeClass('fa-moon');
    window.$('.menu-theme-toggle > i').addClass('fa-sun');
  }

  if (save){
      window.localStorage.setItem('preference-theme',theme);
  }
}

export function enableLayout(newLayout = 'default',save = false){ 
  let layout= newLayout;

  if(layout === 'violet'){
    window.$("body").removeClass('green');
    window.$("body").addClass('violet');
  }else if(layout === 'green'){
    window.$("body").removeClass('violet');
    window.$("body").addClass('green');
  }else if(layout === 'default'){
    window.$("body").removeClass('green');
    window.$("body").removeClass('violet');
  }

  if (save){
      window.localStorage.setItem('preference-layout',layout);
  }
}

export function returnThemeBasedOnOS() {
    return (window.matchMedia('(prefers-color-scheme)').media !== 'not all' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)?'dark':'light';
}