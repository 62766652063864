import React from 'react';
import AnimatedBG from './components/AnimatedBG';
import PreLoader from './components/PreLoader';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Resume from './components/Resume';
import Sponsor from './components/Sponsor';
import NotFound from './components/NotFound';
import License from './components/License';
import Portfolio from './components/Portfolio';
import Portfolio_project_1 from './components/Portfolio_project_1';
import Portfolio_project_2 from './components/Portfolio_project_2';
import Portfolio_project_3 from './components/Portfolio_project_3';
import Contact from './components/Contact';
import Blog from './components/Blog';
import Blog_List from './components/Blog_List';
import Blog_Post_1 from './components/Blog_Posts/Blog_Post_1';
import Blog_Post_2 from './components/Blog_Posts/Blog_Post_2';
import Blog_Post_3 from './components/Blog_Posts/Blog_Post_3';
import Blog_Post_4 from './components/Blog_Posts/Blog_Post_4';
import Blog_Post_5 from './components/Blog_Posts/Blog_Post_5';
import Blog_Post_6 from './components/Blog_Posts/Blog_Post_6';
import Blog_Post_7 from './components/Blog_Posts/Blog_Post_7';


import {enableTheme, enableLayout, returnThemeBasedOnOS} from './lib/themeConfig';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';

export default class App extends React.Component{

  componentDidMount(){

    let theme = window.localStorage.getItem('preference-theme');
    let layout= window.localStorage.getItem('preference-layout');
    
    if(theme === 'light'){
        enableTheme('light',false);
    }else if(theme === 'dark'){
        enableTheme('dark',false);
    }else{
        enableTheme(returnThemeBasedOnOS(),false);
    }

    if(layout === 'violet'){
        enableLayout('violet',false);
    }else if(layout === 'green'){
        enableLayout('green',false);
    }else{
        enableLayout('default');
    }

    window.$(window).on('storage', e => {
      if (e.originalEvent.key === 'preference-theme'){
          (e.originalEvent.newValue === 'dark')?
              enableTheme('dark'):enableTheme('light');
      }

      if (e.originalEvent.key === 'preference-layout'){
          if (e.originalEvent.newValue === 'violet'){
              enableLayout('violet');
          }else if (e.originalEvent.newValue === 'green'){
              enableLayout('green');
          }else{
              enableLayout('default');
          }
      }
    });

    window.$("body").magnificPopup({
      fixedContentPos: !1,
      delegate: "a.lightbox",
      type: "image",
      removalDelay: 300,
      mainClass: "mfp-fade",
      image: {
          titleSrc: "title",
          gallery: {
              enabled: !0
          }
      },
      iframe: {
          markup: '<div class="mfp-iframe-scaler"><div class="mfp-close"></div><iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe><div class="mfp-title mfp-bottom-iframe-title"></div></div>',
          patterns: {
              youtube: {
                  index: "youtube.com/",
                  id: null,
                  src: "%id%?autoplay=1"
              },
              vimeo: {
                  index: "vimeo.com/",
                  id: "/",
                  src: "//player.vimeo.com/video/%id%?autoplay=1"
              },
              gmaps: {
                  index: "//maps.google.",
                  src: "%id%&output=embed"
              }
          },
          srcAction: "iframe_src"
      },
      callbacks: {
          markupParse: function (e, a, t) {
              a.title = t.el.attr("title")
          }
      }
    });

  }

  render(){
    return (
      <Router>
        <AnimatedBG/>
        <PreLoader/>
        <ScrollToTop/>
        <div className="page-scroll">
          <div id="page_container" className="page-container bg-move-effect" 
                data-animation="transition-flip-in-right">
            <Header/>
            <Switch>
              <Route exact path="/" component={About}></Route>
              <Route exact path="/resume" component={Resume}></Route>
              <Route exact path="/portfolio" component={Portfolio}></Route>
              <Route exact path='/portfolio/portfolio-project-1' component={Portfolio_project_1}></Route>
              <Route exact path='/portfolio/portfolio-project-2' component={Portfolio_project_2}></Route>
              <Route exact path='/portfolio/portfolio-project-3' component={Portfolio_project_3}></Route>
              <Route exact path='/blog' component={Blog}></Route>
              <Route exact path='/blog-list' component={Blog_List}></Route>
              <Route exact path='/blog-posts/blog-post-1' component={Blog_Post_1}></Route>
              <Route exact path='/blog-posts/blog-post-2' component={Blog_Post_2}></Route>
              <Route exact path='/blog-posts/blog-post-3' component={Blog_Post_3}></Route>
              <Route exact path='/blog-posts/blog-post-4' component={Blog_Post_4}></Route>
              <Route exact path='/blog-posts/blog-post-5' component={Blog_Post_5}></Route>
              <Route exact path='/blog-posts/blog-post-6' component={Blog_Post_6}></Route>
              <Route exact path='/blog-posts/blog-post-7' component={Blog_Post_7}></Route>
              <Route exact path='/sponsor' component={Sponsor}></Route>
              <Route exact path='/contact' component={Contact}></Route>
              <Route exact path='/license' component={License}></Route>
              <Route component={NotFound}></Route> 
            </Switch>
            <Footer/>
          </div>
        </div>
      </Router>

    );
  }
}
