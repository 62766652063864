import React from 'react';

class Resume extends React.Component{

  componentDidMount(){
    var t = ""; 
    window.$(".skill-container").each(function () {
      var e = window.$(this).attr("data-value");
      if (101 <= e && (e = "100"), void 0 !== e) {
          var a = window.$(this).attr("id");
          t += "#" + a + " .skill-percentage { width: " + e + "%; } ";
      }
  }); 
  window.$("head").append('<style data-styles="leven-theme-skills-css" type="text/css">' + t + "</style>");
  }

  render(){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div className="page-title">
              <h1>Resume</h1>
              <div className="page-subtitle">
                <h4> 6 Years of Experience</h4>
              </div>
            </div>
            <div id="content" className="page-content site-content single-post" role="main">
              <div className="row">
                <div className=" col-xs-12 col-sm-6 ">
                  <div className="block-title">
                    <h2>Education</h2>
                  </div>
                  <div id="timeline_1" className="timeline clearfix">
                    <div className="timeline-item clearfix">
                      <h5 className="item-period ">2022</h5>
                      <span className="item-company">REVA university</span>
                      <h4 className="item-title">Masters of CA</h4>
                      <p>Duis posuere, quam non imperdiet egestas, eros enim mattis mauris, in posuere lacus arcu quis felis. Etiam interdum erat non enim venenatis fermentum.</p>
                    </div>
                    <div className="timeline-item clearfix">
                      <h5 className="item-period ">2020</h5>
                      <span className="item-company">FIEM collage</span>
                      <h4 className="item-title">Bachelor of CA</h4>
                      <p>Aliquam tincidunt malesuada tortor vitae iaculis. In eu turpis iaculis, feugiat risus quis, aliquet urna. Quisque fringilla mollis risus, eu pulvinar dolor.</p>
                    </div>
                    <div className="timeline-item clearfix">
                      <h5 className="item-period ">2017</h5>
                      <span className="item-company">Future campus School</span>
                      <h4 className="item-title">Higher Education</h4>
                      <p>Maecenas finibus nec sem ut imperdiet. Ut tincidunt est ac dolor aliquam sodales. Phasellus sed mauris hendrerit, laoreet sem in, lobortis ante.</p>
                    </div>
                  </div>
                </div>
                <div className=" col-xs-12 col-sm-6 ">
                  <div className="block-title">
                    <h2>Experience</h2>
                  </div>
                  <div id="timeline_2" className="timeline clearfix">
                    <div className="timeline-item clearfix">
                      <h5 className="item-period current">2020 - Current</h5>
                      <span className="item-company">Self tought</span>
                      <h4 className="item-title">Backend development &amp; Design patterns</h4>
                      <p>Praesent dignissim sollicitudin justo, sed elementum quam lacinia quis. Phasellus eleifend tristique posuere. Sed vitae dui nec magna.</p>
                    </div>
                    <div className="timeline-item clearfix">
                      <h5 className="item-period ">2018 - 2020</h5>
                      <span className="item-company">University project</span>
                      <h4 className="item-title">Data Analysis</h4>
                      <p>Maecenas tempus faucibus rutrum. Duis eu aliquam urna. Proin vitae nulla tristique, ornare felis id, congue libero. Nam volutpat euismod quam.</p>
                    </div>
                    <div className="timeline-item clearfix">
                      <h5 className="item-period ">2016 - 2018</h5>
                      <span className="item-company">Ardent Solutions</span>
                      <h4 className="item-title">Java development</h4>
                      <p>Duis mollis nunc quis quam viverra venenatis. Nulla nulla arcu, congue vitae nunc ac, sodales ultricies diam. Nullam justo leo, tincidunt sit amet.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12 ">
                  <div className="p-20" />
                  <div className="block-title">
                    <h2>Certificates</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-6 ">
                  <a href="/assets/img/clients/1.jpg" className="lightbox">
                    <div className="certificate-item clearfix">
                      <div className="certi-logo">
                        <img loading="lazy" src="/assets/img/clients/client-7.png" alt="logo" />
                      </div>
                      <div className="certi-content">
                        <div className="certi-title">
                          <h4>Psyhology of Intertnation Design</h4>
                        </div>
                        <div className="certi-id">
                          <span>Membership ID: XXXX</span>
                        </div>
                        <div className="certi-date">
                          <span>19 April 2018</span>
                        </div>
                        <div className="certi-company">
                          <span />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className=" col-xs-12 col-sm-6 ">
                  <a href="/assets/img/clients/1.jpg" className="lightbox">
                    <div className="certificate-item clearfix">
                      <div className="certi-logo">
                        <img loading="lazy" src="/assets/img/clients/2.png" alt="logo" />
                      </div>
                      <div className="certi-content">
                        <div className="certi-title">
                          <h4>Psyhology of Intertnation Design</h4>
                        </div>
                        <div className="certi-id">
                          <span>Membership ID: XXXX</span>
                        </div>
                        <div className="certi-date">
                          <span>19 April 2018</span>
                        </div>
                        <div className="certi-company">
                          <span />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12 ">
                  <div className="p-40" />
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-6 ">
                  <div className="block-title">
                    <h2>Design Skills</h2>
                  </div>
                  <div id="skills_1" className="skills-info skills-first-style">
                    {/* Skill 1 */}
                    <div className="clearfix">
                      <h4>Singleton</h4>
                      <div className="skill-value">95%</div>
                    </div>
                    <div id="skill_1" data-value={95} className="skill-container">
                      <div className="skill-percentage" />
                    </div>
                    {/* /Skill 1 */}
                    {/* Skill 2 */}
                    <div className="clearfix">
                      <h4>Builder</h4>
                      <div className="skill-value">75%</div>
                    </div>
                    <div id="skill_2" data-value={75} className="skill-container">
                      <div className="skill-percentage" />
                    </div>
                    {/* /Skill 2 */}
                    {/* Skill 3 */}
                    <div className="clearfix">
                      <h4>MVC</h4>
                      <div className="skill-value">85%</div>
                    </div>
                    <div id="skill_3" data-value={85} className="skill-container">
                      <div className="skill-percentage" />
                    </div>
                    {/* Skill 3 */}
                    {/* Skill 4 */}
                    <div className="clearfix">
                      <h4>Factory</h4>
                      <div className="skill-value">90%</div>
                    </div>
                    <div id="skill_4" data-value={90} className="skill-container">
                      <div className="skill-percentage" />
                    </div>
                    {/* /Skill 4 */}
                  </div>
                </div>
                <div className=" col-xs-12 col-sm-6 ">
                  <div className="block-title">
                    <h2>Coding Skills</h2>
                  </div>
                  <div id="skills_2" className="skills-info skills-first-style">
                    {/* Skill 5 */}
                    <div className="clearfix">
                      <h4>Java</h4>
                      <div className="skill-value">100%</div>
                    </div>
                    <div id="skill_5" data-value={100} className="skill-container">
                      <div className="skill-percentage" />
                    </div>
                    {/* /Skill 5 */}
                    {/* Skill 6 */}
                    <div className="clearfix">
                      <h4>HTML / CSS</h4>
                      <div className="skill-value">90%</div>
                    </div>
                    <div id="skill_6" data-value={90} className="skill-container">
                      <div className="skill-percentage" />
                    </div>
                    {/* /Skill 6 */}
                    {/* Skill 7 */}
                    <div className="clearfix">
                      <h4>JavaScript</h4>
                      <div className="skill-value">90%</div>
                    </div>
                    <div id="skill_7" data-value={90} className="skill-container">
                      <div className="skill-percentage" />
                    </div>
                    {/* /Skill 7 */}
                    {/* Skill 8 */}
                    <div className="clearfix">
                      <h4>Python</h4>
                      <div className="skill-value">85%</div>
                    </div>
                    <div id="skill_8" data-value={85} className="skill-container">
                      <div className="skill-percentage" />
                    </div>
                    {/* /Skill 8 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Resume;
