import React from 'react';
import Offline from './Offline';
import ConnectionChange from '../lib/connection_change';

export default class Blog_List extends React.Component{

  state = {
    isConnected: true
  };

  handleConnectionChange = async () => {return this.setState({ isConnected: await  ConnectionChange()});}

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  componentDidMount(){
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
    
    window.$("body").addClass("masthead-fixed list-view full-width");
  }
  
  render(){
    if(this.state.isConnected){
    return(
      <div id="main" className="site-main">
        <div id="main-content" className="single-page-content">
          <div id="primary" className="content-area">
            <div id="content" className="page-content site-content" role="main">
              <article id="post-139" className="post-139 post type-post status-publish format-standard has-post-thumbnail hentry category-wordpress tag-design tag-plugin tag-wordpress">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">WordPress</a></span>
                  </div>{/* .entry-meta */}
                  <h3 className="entry-title"><a href="/blog-posts/blog-post-1" rel="bookmark">How to Make a WordPress Plugin Extensible</a></h3>
                </header>{/* .entry-header */}
                <a className="post-thumbnail" href="/blog-posts/blog-post-1">
                  <img loading="lazy" src="/assets/img/blog/blog_post_1_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </a>
                <div className="post-content">
                  <div className="entry-summary">
                  </div>{/* .entry-summary */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T08:29:37+00:00">December
                            4, 2019</time></a></span>
                      <span className="author vcard"><a className="url fn n" href="#/" rel="author">
                          <i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="#/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">design</a><a href="#/" rel="tag">plugin</a><a href="#/" rel="tag">WordPress</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <article id="post-136" className="post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-design tag-design tag-icon tag-ui tag-ux">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">Design</a></span>
                  </div>{/* .entry-meta */}
                  <h3 className="entry-title"><a href="/blog-posts/blog-post-5" rel="bookmark">6 Easy Steps to Better Icon Design</a></h3>
                </header>{/* .entry-header */}
                <a className="post-thumbnail" href="/blog-posts/blog-post-5">
                  <img loading="lazy" src="/assets/img/blog/blog_post_5_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </a>
                <div className="post-content">
                  <div className="entry-summary">
                  </div>{/* .entry-summary */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T08:24:52+00:00">December
                            4, 2019</time></a></span><span className="author vcard">
                        <a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">design</a><a href="#/" rel="tag">icon</a><a href="#/" rel="tag">ui</a><a href="#/" rel="tag">ux</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <article id="post-133" className="post-133 post type-post status-publish format-standard has-post-thumbnail hentry category-ui tag-design tag-menu tag-ui tag-video tag-vimeo tag-youtube">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">UI</a></span>
                  </div>{/* .entry-meta */}
                  <h3 className="entry-title"><a href="/blog-posts/blog-post-6" rel="bookmark">Creative and Innovative Navigation Designs</a></h3>
                </header>{/* .entry-header */}
                <a className="post-thumbnail" href="/blog-posts/blog-post-6">
                  <img loading="lazy" src="/assets/img/blog/blog_post_6_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </a>
                <div className="post-content">
                  <div className="entry-summary">
                  </div>{/* .entry-summary */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T08:11:44+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">design</a><a href="#/" rel="tag">menu</a><a href="#/" rel="tag">ui</a><a href="#/" rel="tag">video</a><a href="#/" rel="tag">vimeo</a><a href="#/" rel="tag">youtube</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <article id="post-123" className="post-123 post type-post status-publish format-standard has-post-thumbnail hentry category-design tag-design tag-photoshop tag-sketch tag-soundcloud">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">Design</a></span>
                  </div>{/* .entry-meta */}
                  <h3 className="entry-title"><a href="/blog-posts/blog-post-4" rel="bookmark">Why I Switched to Sketch For UI Design</a></h3>
                </header>{/* .entry-header */}
                <a className="post-thumbnail" href="/blog-posts/blog-post-4">
                  <img loading="lazy" src="/assets/img/blog/blog_post_4_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </a>
                <div className="post-content">
                  <div className="entry-summary">
                  </div>{/* .entry-summary */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T07:28:16+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">design</a><a href="#/" rel="tag">photoshop</a><a href="#/" rel="tag">sketch</a><a href="#/" rel="tag">soundcloud</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <article id="post-120" className="post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-e-commerce tag-cms tag-e-commerce">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">E-Commerce</a></span>
                  </div>{/* .entry-meta */}
                  <h3 className="entry-title"><a href="/blog-posts/blog-post-3" rel="bookmark">An Overview of E-Commerce Platforms</a></h3>
                </header>{/* .entry-header */}
                <a className="post-thumbnail" href="/blog-posts/blog-post-3">
                  <img loading="lazy" src="/assets/img/blog/blog_post_3_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </a>
                <div className="post-content">
                  <div className="entry-summary">
                  </div>{/* .entry-summary */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T07:08:39+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">cms</a><a href="#/" rel="tag">e-commerce</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <article id="post-114" className="post-114 post type-post status-publish format-standard has-post-thumbnail hentry category-e-commerce tag-design tag-table tag-ui tag-ux tag-web">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">E-Commerce</a></span>
                  </div>{/* .entry-meta */}
                  <h3 className="entry-title"><a href="/blog-posts/blog-post-2" rel="bookmark">Designing the Perfect Feature Comparison Table</a></h3>
                </header>{/* .entry-header */}
                <a className="post-thumbnail" href="/blog-posts/blog-post-2">
                  <img loading="lazy" src="/assets/img/blog/blog_post_2_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </a>
                <div className="post-content">
                  <div className="entry-summary">
                  </div>{/* .entry-summary */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T06:53:33+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">design</a><a href="#/" rel="tag">table</a><a href="#/" rel="tag">ui</a><a href="#/" rel="tag">ux</a><a href="#/" rel="tag">web</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <article id="post-96" className="post-96 post type-post status-publish format-standard has-post-thumbnail hentry category-ui tag-animate tag-bar tag-design tag-progress">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="#/" rel="category tag">UI</a></span>
                  </div>{/* .entry-meta */}
                  <h3 className="entry-title"><a href="/blog-posts/blog-post-7" rel="bookmark">Best Practices for Animated Progress Indicators</a></h3>
                </header>{/* .entry-header */}
                <a className="post-thumbnail" href="/blog-posts/blog-post-7">
                  <img loading="lazy" src="/assets/img/blog/blog_post_7_full.jpg" className="attachment-full size-full wp-post-image" alt="" /> </a>
                <div className="post-content">
                  <div className="entry-summary">
                  </div>{/* .entry-summary */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="#/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-12-04T06:36:59+00:00">December
                            4, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="#/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" target="_blank" rel="noreferrer" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                    <span className="tags"><a href="#/" rel="tag">animate</a><a href="#/" rel="tag">bar</a><a href="#/" rel="tag">design</a><a href="#/" rel="tag">progress</a></span> </div>
                </div>
              </article>{/* #post-## */}
              <article id="post-1" className="post-1 post type-post status-publish format-standard hentry category-uncategorized">
                <header className="entry-header">
                  <div className="entry-meta entry-meta-top">
                    <span><a href="https://lmpixels.com/wp/leven-wp/full-width/category/uncategorized/" rel="category tag">Uncategorized</a></span>
                  </div>{/* .entry-meta */}
                  <h3 className="entry-title"><a href="https://lmpixels.com/wp/leven-wp/full-width/2019/11/28/hello-world/" rel="bookmark">Hello world!</a></h3>
                </header>{/* .entry-header */}
                <div className="post-content">
                  <div className="entry-summary">
                    <p>Welcome to WordPress. This is your first post. Edit or delete it, then start
                      writing!</p>
                  </div>{/* .entry-summary */}
                  <div className="entry-meta entry-meta-bottom">
                    <div className="date-author">
                      <span className="entry-date"><a href="https://lmpixels.com/wp/leven-wp/full-width/2019/11/28/hello-world/" rel="bookmark"><i className="far fa-clock" /> <time className="entry-date" dateTime="2019-11-28T13:52:20+00:00">November
                            28, 2019</time></a></span><span className="author vcard"><a className="url fn n" href="https://lmpixels.com/wp/leven-wp/full-width/author/lmpixels/" rel="author"><i className="fa fa-fw fa-user" /> lmpixels</a></span>
                    </div>
                    {/* Share Buttons */}
                    <div className="entry-share btn-group share-buttons">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/share?url=http://example.com/"  className="btn" rel="noreferrer" target="_blank" title="Share on Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://www.linkedin.com/shareArticle?mini=true&url=http://example.com/" className="btn" title="Share on LinkedIn">
                        <i className="fab fa-linkedin-in" />
                      </a>
                      <a href="http://www.digg.com/submit?url=http://example.com/" className="btn" title="Share on Digg">
                        <i className="fab fa-digg" />
                      </a>
                    </div>
                    {/* /Share Buttons */}
                  </div>
                  <div className="post-tags">
                  </div>
                </div>
              </article>{/* #post-## */}
            </div>{/* #content */}
          </div>{/* #primary */}
        </div>{/* #main-content */}
      </div>
    );
    }else{
      return(<Offline/>);
    }
  }
}