import React from 'react';
import {Link} from 'react-router-dom';
import {enableTheme,enableLayout} from '../lib/themeConfig';

export default class Header extends React.Component{

  componentDidMount(){

    var layouts=['violet','green','default'];
    var layoutToggleCounter=0;

    switch(window.localStorage.getItem('preference-layout')){
      case layouts[0]:
        layoutToggleCounter=0;
        break;
      case layouts[1]:
        layoutToggleCounter=1;
        break;
      default:
        layoutToggleCounter=2;
    }

    window.$('.menu-theme-toggle').on('click', ()=>{
      window.$("body").hasClass('dark-mode')?
          enableTheme('light',true):enableTheme('dark',true);
    });

    window.$('.theme-toggle-light').on('click', function(){
        enableTheme('light',true);
    });

    window.$('.theme-toggle-dark').on('click', function(){
        enableTheme('dark',true);
    });


    window.$('.menu-layout-toggle').on('click', ()=>{
      enableLayout(layouts[(++layoutToggleCounter)%layouts.length],true);
    });
    window.$('.layout-toggle-v').on('click', function(){
        enableLayout(layouts[0],true);
    });

    window.$('.layout-toggle-g').on('click', function(){
        enableLayout(layouts[1],true);
    });

    window.$('.layout-toggle-def').on('click', function(){
        enableLayout(layouts[2],true);
    });

    window.$(".menu-toggle").on("click", function () {
      window.$(".site-nav").addClass("animate");
      window.$(".site-nav").toggleClass("mobile-menu-hide");
      setTimeout(()=>{
        window.$(".menu-toggle i").toggleClass('fa-align-right');
        window.$(".menu-toggle i").toggleClass('fa-times');
      },350)
    });
    window.$(document).on("click", function(event){
        if(!window.$(".site-nav").has(event.target).length && !window.$(".menu-toggle").has(event.target).length &&
            !(window.$(event.target).hasClass('fa-sun') || window.$(event.target).hasClass('fa-moon'))){
              window.$(".site-nav").addClass("animate");
              window.$(".site-nav").addClass("mobile-menu-hide");
            setTimeout(()=>{
              window.$(".menu-toggle i").removeClass('fa-times');
              window.$(".menu-toggle i").addClass('fa-align-right');
            },350)
        }
    });
  }

  render(){
  return(
    <header id="site_header" className="header">
        <div className="header-content clearfix">
          {/* Text Logo */}
          <div className="text-logo">
            <Link to="/">
              <div className="logo-symbol">R</div>
              <div className="logo-text">Rajarshi <span>Kundu</span></div>
            </Link>
          </div>
          {/* /Text Logo */}
          {/* Navigation */}
          <div className="site-nav mobile-menu-hide">
            <ul className="leven-classic-menu site-main-menu">
              <li  className="menu-item">
                <Link to="/">About Me</Link>
              </li>
              <li  className="menu-item">
                <Link to="/resume">Resume</Link>
              </li>
              <li  className="menu-item">
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li  className="menu-item">
                <Link to="/blog">Blog</Link>
              </li>
              <li  className="menu-item">
                <Link to="/sponsor">Sponsor</Link>
              </li>
              <li  className="menu-item">
                <Link to="/contact">Contact</Link>
              </li>
              <li className="menu-item menu-item-has-children" type="theme">
                <a href="#/">Theme</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="#/" className="theme-toggle-dark">Dark Mode</a>
                  </li>
                  <li className="menu-item">
                    <a href="#/" className="theme-toggle-light">Light Mode</a>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children" type="layout">
                <a href="#/">Layout</a>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a href="#/" className="layout-toggle-v" style={{color: '#7066FF'}}>Violet</a>
                  </li>
                  <li className="menu-item">
                    <a href="#/" className="layout-toggle-g" style={{color: '#4ac5bf'}}>Green</a>
                  </li>
                  <li className="menu-item">
                    <a href="#/" className="layout-toggle-def" style={{color: '#007ced'}}>Blue</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          {/* Mobile Menu Toggle */}
          <div className="toggles mobile-visible">
          <a className="menu-layout-toggle">
              <i className="fas fa-adjust" />
            </a>
            <a className="menu-theme-toggle">
              <i className="fa fa-moon" />
            </a>
            <a className="menu-toggle">
              <i className="fa fa-align-right" />
            </a>
          </div>
          {/* Mobile Menu Toggle */}
        </div>
      </header>
  );};
}