
async function isLocalhost(){ return Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)};

export default async function ConnectionChange(){
  const condition = window.navigator.onLine ? 'online' : 'offline';
  if (condition === 'online') {
    if(await isLocalhost()){
      return true;
    }else{
      try{
        await fetch('//google.com', {mode: 'no-cors',});
        return true;
      }catch{
        return false;
      }
    }
  }
  return false;
}
