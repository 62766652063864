import React from 'react';

export default class ScrollToTop extends React.Component {
  componentDidMount(){
    function d() {
      150 < window.$(document.body).scrollTop()? 
      window.$(".lmpixels-scroll-to-top").removeClass("hidden-btn") : window.$(".lmpixels-scroll-to-top").addClass("hidden-btn");
    }

    var l = 15 / window.$(document).height(), n = 15 / window.$(document).width();
    window.$("body").on("mousemove", function (e) {
        var a = e.pageX - window.$(document).width() / 2,
            t = e.pageY - window.$(document).height() / 2,
            o = n * a * -1,
            i = l * t * -1;
        var s;
        window.$(".page-container").hasClass("bg-move-effect")?
          s = window.$(".home-photo .hp-inner:not(.without-move), .lm-animated-bg"):
          s = window.$(".home-photo .hp-inner:not(.without-move)");
        s.addClass("transition");
        s.css({
            "background-position": "calc( 50% + " + o + "px ) calc( 50% + " + i + "px )"
        });
        setTimeout(function () {
            s.removeClass("transition")
        }, 300)
    }).scroll(function () {
        d()
    });

    window.$(".lmpixels-scroll-to-top").on('click',function () {
      return window.$("body,html").animate({
          scrollTop: 0
      }, 400);
  }); d();
  }

  render(){
    return(<div className="lmpixels-scroll-to-top"><i className="lnr lnr-chevron-up"></i></div>);
  }
}